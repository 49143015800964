import { useEffect, useId } from "react";
import * as React from "react";
import MuiModal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import { CardContent, CardHeader, SxProps, Theme } from "@mui/material";
import { useDetectMobile } from "../hooks/useDetectMobile";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: { xs: "calc(100% - 2rem)", sm: "initial" },
};

type ModalProps = {
  children: React.ReactNode;
  heading?: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
};

export const Modal = ({
  heading,
  isOpen,
  onClose,
  children,
  disableEscapeKeyDown,
  disableBackdropClick,
}: ModalProps) => {
  const isMobile = useDetectMobile();
  const modalId = useId();

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    const viewport = document.querySelector('meta[name="viewport"]');
    setTimeout(() => {
      if (viewport) {
        viewport.setAttribute(
          "content",
          "width=1200, initial-scale=1, user-scalable=yes, minimum-scale=1, maximum-scale=5"
        );
      }

      // Scroll to top of page
      window.scrollTo({
        top: document.body.scrollHeight * 0.1,
        left: document.body.scrollWidth * 0.35,
        behavior: "smooth",
      });

      // Force redraw for Safari
      document.body.style.webkitTransform = "scale(1)";
      document.body.style.transform = "scale(1)";
    }, 100);

    // Cleanup
    return () => {
      if (viewport) {
        viewport.setAttribute("content", "width=1200, user-scalable=yes");
      }
      document.body.style.overflow = "auto";
    };
  }, [isOpen, isMobile]);

  const handleOnClose = (_: React.SyntheticEvent, reason: string) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <MuiModal
        id={modalId}
        open={isOpen}
        onClose={handleOnClose}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: "blur(8px)",
              // Ensure backdrop covers entire viewport
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          },
        }}
        disableEscapeKeyDown={!!disableEscapeKeyDown}
      >
        <Card
          sx={{
            ...style,
            // Ensure modal is visible in viewport
            maxHeight: "90vh",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch", // For smooth scrolling in iOS
          }}
        >
          {heading && (
            <CardHeader
              title={heading}
              titleTypographyProps={{ variant: "body1", fontWeight: "bold" }}
            />
          )}
          <CardContent>{children}</CardContent>
        </Card>
      </MuiModal>
    </div>
  );
};
