import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
  DragOverEvent,
  MeasuringStrategy,
  rectIntersection,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { SortableItem } from "../../components/SortableItem";
import { DroppableContainer } from "../../components/DroppableContainer";
import {
  Order,
  OrderStatus,
  getOrderStatusDisplayName,
} from "../../types/order";
import { OrderCard } from "./OrderCard";
import { Box } from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { FillContainer } from "../../components/FillContainer";
import { QuoteRequestErrorModal } from "./QuoteRequestErrorModa";
import { useSession } from "../../hooks/useSession";

type OrderKanbanViewProps = {
  orders: Order[];
  refreshOrders: () => void;
  handleOrderEditClick: (orderGuid: string) => void;
  handleNewChangeOrderClick: (orderGuid: string) => void;
  handleDeleteOrderClick: (orderGuid: string) => void;
  handleConvertToQuoteClick: (prospectGuid: string) => void;
};

export const OrderKanbanView: React.FC<OrderKanbanViewProps> = ({
  orders,
  refreshOrders,
  handleOrderEditClick,
  handleNewChangeOrderClick,
  handleDeleteOrderClick,
  handleConvertToQuoteClick,
}) => {
  const [items, setItems] = useState<Record<string, Order[]>>({});
  const [counts, setCounts] = useState<Record<string, number>>({});
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [draggingOrder, setDraggingOrder] = useState<Order | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { user } = useSession();

  const {
    request: updateRequest,
    data: updateData,
    error: updateError,
  } = useApiRequest<Order>();

  const findOrder = useCallback(
    (orderGuid: UniqueIdentifier) => {
      return orders.find((order) => order.orderGuid === orderGuid);
    },
    [orders]
  );

  const updateCounts = useCallback(() => {
    setCounts(
      Object.entries(items).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.length }),
        {}
      )
    );
  }, [items]);

  const transformOrders = useCallback(() => {
    const newItems: Record<Partial<OrderStatus>, Order[]> = {
      p: [],
      q: [],
      o: [],
      f: [],
      i: [],
      c: [],
      v: [],
      s: [],
      a: [],
    };

    orders.forEach((order) => {
      const os = (user?.isRetailUser && ["o","f","i"].includes(order.orderStatus)) ? "o" : order.orderStatus;
      if (!newItems[os]) {
        newItems[os] = [];
      }
      newItems[os].push(order);
    });

    setItems(newItems);
    setCounts(
      Object.entries(newItems).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value.length }),
        {}
      )
    );
  }, [orders,user?.isRetailUser]);

  const orderedItems = useMemo(() => {
    const orderPhase: Record<string, number> = {
      qpd: 1, // Draft
      qpr: 2, // Pending Retail
      qpn: 3, // Needs Review / Pending Mfg
      qpo: 4, // Order Ready
    };

    return Object.entries(items).reduce((acc, [key, value]) => {
      // Only show Prospects column for a Retail user
      if (!user?.isRetailUser && key === "p") {
        return [];
      } else if (key === "q") {
        return {
          ...acc,
          [key]: value.sort((a, b) => {
            const phaseCompare =
              orderPhase[a.quotePhase] - orderPhase[b.quotePhase];
            if (phaseCompare !== 0) {
              return phaseCompare;
            }
            return a.createdTimestamp - b.createdTimestamp;
          }),
        };
      } else {
        return {
          ...acc,
          [key]: value.sort((a, b) => a.createdTimestamp - b.createdTimestamp),
        };
      }
    }, {});
  }, [items, user]);

  useEffect(() => {
    if (!updateData) {
      return;
    }
    refreshOrders();
  }, [refreshOrders, updateData]);

  useEffect(() => {
    transformOrders();
  }, [transformOrders, orders]);

  useEffect(() => {
    if (updateError?.response?.status === 400) {
      refreshOrders();
      setShowErrorModal(true);
    }
  }, [updateError, refreshOrders]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const isStatusChangeRejected = (
    dragId: UniqueIdentifier,
    drop: UniqueIdentifier | undefined
  ) => {
    if (!dragId || !drop) {
      return true;
    }

    if (
      draggingOrder?.orderStatus === "q" &&
      !["o", "a"].includes(drop as string)
    ) {
      return true;
    }
    // Only allow a drag from an allowed save as option
    if (!draggingOrder?.saveAsOptions?.orderStatuses?.includes(drop as any)) {
      return true;
    }

    return false;
  };

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key].some((item) => item.orderGuid === id)
    );
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
    setDraggingOrder(findOrder(id) || null);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const { id } = active;
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (isStatusChangeRejected(id, overContainer)) {
      return;
    }

    if (!activeContainer || !overContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      setItems((prev) => {
        const activeItems = prev[activeContainer];
        const overItems = prev[overContainer];

        const activeIndex = activeItems.findIndex(
          (item) => item.orderGuid === id
        );
        const overIndex = overItems.findIndex(
          (item) => item.orderGuid === overId
        );

        let newIndex;
        if (overId in prev) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowLastItem =
            over &&
            overIndex === overItems.length - 1 &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;
          const modifier = isBelowLastItem ? 1 : 0;

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...prev,
          [activeContainer]: [
            ...prev[activeContainer].filter((item) => item.orderGuid !== id),
          ],
          [overContainer]: [
            ...prev[overContainer].slice(0, newIndex),
            activeItems[activeIndex],
            ...prev[overContainer].slice(newIndex, prev[overContainer].length),
          ],
        };
      });
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    const { id } = active;
    const overId = over?.id;

    if (!overId) {
      setActiveId(null);
      setDraggingOrder(null);
      return;
    }

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (isStatusChangeRejected(id, overContainer)) {
      if (draggingOrder?.orderStatus === "q") {
        setShowErrorModal(true);
      }
      refreshOrders();
      setActiveId(null);
      return;
    }

    if (!activeContainer || !overContainer) {
      setActiveId(null);
      setDraggingOrder(null);
      return;
    }

    if (activeContainer !== overContainer) {
      const activeIndex = items[activeContainer].findIndex(
        (item) => item.orderGuid === id
      );
      const overIndex = items[overContainer].findIndex(
        (item) => item.orderGuid === overId
      );

      if (activeIndex !== overIndex) {
        setItems((items) => ({
          ...items,
          [overContainer]: arrayMove(
            items[overContainer],
            activeIndex,
            overIndex
          ),
        }));
      }
    }

    updateRequest(`/orders/${id}/edit`, {
      method: "POST",
      data: {
        orderStatus: overContainer,
      },
    });

    setActiveId(null);
    setDraggingOrder(null);
    updateCounts();
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <FillContainer>
          <Box
            sx={{
              display: "flex",
              paddingBottom: 2,
              paddingX: 3,
            }}
          >
            {Object.keys(orderedItems).map((containerId) => (
              <DroppableContainer
                key={containerId}
                id={containerId}
                heading={getOrderStatusDisplayName(containerId as OrderStatus, user?.isRetailUser)}
                count={counts[containerId]}
                isOver={
                  activeId ? containerId === findContainer(activeId) : false
                }
                hidden={user?.isRetailUser && ['f','i'].includes(containerId as OrderStatus)}
              >
                <SortableContext
                  items={items[containerId].map((item) => item.orderGuid)}
                  strategy={horizontalListSortingStrategy}
                >
                  {items[containerId].map((item) => (
                    <SortableItem key={item.orderGuid} id={item.orderGuid}>
                      <OrderCard
                        order={item}
                        handleOrderEditClick={handleOrderEditClick}
                        handleNewChangeOrderClick={handleNewChangeOrderClick}
                        handleDeleteOrderClick={handleDeleteOrderClick}
                        handleConvertToQuoteClick={handleConvertToQuoteClick}
                      />
                    </SortableItem>
                  ))}
                </SortableContext>
              </DroppableContainer>
            ))}
          </Box>
        </FillContainer>
        <DragOverlay>
          {activeId ? (
            <Box sx={{ opacity: 0.9 }}>
              <SortableItem id={activeId}>
                <OrderCard
                  order={
                    items[findContainer(activeId) as string].find(
                      (item) => item.orderGuid === activeId
                    )!
                  }
                  handleOrderEditClick={handleOrderEditClick}
                  handleNewChangeOrderClick={handleNewChangeOrderClick}
                  handleDeleteOrderClick={handleDeleteOrderClick}
                  handleConvertToQuoteClick={handleConvertToQuoteClick}
                />
              </SortableItem>
            </Box>
          ) : null}
        </DragOverlay>
      </DndContext>
      {showErrorModal && (
        <QuoteRequestErrorModal onClose={() => setShowErrorModal(false)} />
      )}
    </>
  );
};
