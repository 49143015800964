import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import { Link, useNavigate, useLocation, generatePath } from "react-router-dom";
import { useSession } from "../hooks/useSession";
import { UserRoles } from "../types/userRole";
import { routes } from "../routes";
import { useApiRequest } from "../hooks/useApiRequest";
import { UserSession } from "../types/user";
import { VerifyCodeModal } from "./VerifyCodeModal";
import { useDetectMobile } from "../hooks/useDetectMobile";
import { Modal } from "./Modal";

type PageRoute = {
  label: string;
  path: string;
  roles: readonly UserRoles[];
};

type TopBarProps = {
  buyerMode?: boolean;
};

export const TopBar: React.FC<TopBarProps> = ({ buyerMode = false }) => {
  const { logout, user, login } = useSession();
  const isMobile = useDetectMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showVerifyCodeModal, setShowVerifyCodeModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { data: activateBuyerModeData, request: activateBuyerModeRequest } =
    useApiRequest<UserSession>();

  const { data: deactivateBuyerModeData, request: deactivateBuyerModeRequest } =
    useApiRequest<UserSession>();

  const { request: cancelDeactivateBuyerModeRequest } = useApiRequest();

  const userInitials =
    user?.userFirstName && user?.userLastName
      ? `${user.userFirstName.charAt(0)} ${user.userLastName.charAt(0)}`
      : "";

  const userProfileImageSrc = user?.userPhotoUrl
    ? `${process.env.REACT_APP_API_BASE_URL}/${user?.userPhotoUrl}`
    : undefined;

  const pages = useMemo(() => {
    if (!user || buyerMode) {
      return [];
    }
    const allPages: PageRoute[] = [
      {
        label: routes.quotesAndOrders.label,
        path: generatePath(routes.quotesAndOrders.path, {
          tenantGuid: user?.tenantGuid ?? "",
        }),
        roles: routes.quotesAndOrders.roles,
      },
      {
        label: routes.reporting.label,
        path: routes.reporting.path,
        roles: routes.reporting.roles,
      },
      {
        label: routes.manageData.label,
        path: routes.manageData.path,
        roles: routes.manageData.roles,
      },
      {
        label: routes.manageTenants.label,
        path: routes.manageTenants.path,
        roles: routes.manageTenants.roles,
      },
      {
        label: routes.manageSystemAdmins.label,
        path: routes.manageSystemAdmins.path,
        roles: routes.manageSystemAdmins.roles,
      },
    ];

    const accessiblePages = allPages.filter((page) =>
      page.roles.includes(user.userRole)
    );

    return accessiblePages;
  }, [user, buyerMode]);

  const currentTabIndex = useMemo(() => {
    return pages.findIndex((page) => {
      return (
        location.pathname.startsWith(page.path) &&
        (location.pathname[page.path.length] === routes.home.path ||
          location.pathname[page.path.length] === undefined)
      );
    });
  }, [location.pathname, pages]);

  // This is required because on initial load the userSession isn't loaded yet
  useEffect(() => {
    if (currentTabIndex === -1) {
      setTabValue(0);
    } else {
      setTabValue(currentTabIndex + 1);
    }
  }, [currentTabIndex]);

  // Add 1 to the index to account for the empty tab
  const [tabValue, setTabValue] = useState(currentTabIndex + 1);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setIsModalOpen(true);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setIsModalOpen(false);
    setAnchorElUser(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleLogout = () => {
    logout();
    navigate(routes.login.path);
  };

  const handleActivateBuyerMode = () => {
    activateBuyerModeRequest("retailer/buyermode/activate", {
      method: "GET",
    });
  };

  const handleDeactivateBuyerMode = () => {
    deactivateBuyerModeRequest("retailer/buyermode/deactivate", {
      method: "GET",
    });
  };
  const handleCancelDeactivateBuyerMode = () => {
    cancelDeactivateBuyerModeRequest("retailer/buyermode/canceldeactivate", {
      method: "GET",
    });
  };

  const handleSuccessfulVerification = (userSession: UserSession) => {
    login(userSession);
    navigate(routes.home.path);
  };

  useEffect(() => {
    if (activateBuyerModeData) {
      login(activateBuyerModeData);
      navigate(routes.buyerMode.path);
    }
  }, [activateBuyerModeData, navigate, login]);

  useEffect(() => {
    if (deactivateBuyerModeData) {
      setShowVerifyCodeModal(true);
    }
  }, [deactivateBuyerModeData, navigate, login]);

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: "appBar",
        }}
      >
        <AppBar position="static" sx={{ displayPrint: "none" }}>
          <Container maxWidth={false}>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <Link
                  to={routes.home.path}
                  style={{ color: "inherit", textDecoration: "none" }}
                  onClick={() => setTabValue(0)}
                >
                  AtlasAlly
                </Link>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: "block", md: "none" } }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page.label}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to={page.path}
                    >
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Tabs
                  aria-label="simple tabs example"
                  indicatorColor="secondary"
                  textColor="inherit"
                  onChange={handleChange}
                  value={tabValue}
                >
                  <Tab
                    tabIndex={0}
                    sx={{ position: "absolute", top: -9999, left: -9999 }}
                  />
                  {pages.map((page, index) => (
                    <Tab
                      tabIndex={index + 1}
                      key={page.label}
                      label={page.label}
                      component={Link}
                      to={page.path}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userInitials} src={userProfileImageSrc}>
                    {userInitials}
                  </Avatar>
                </IconButton>
                {isMobile && isModalOpen && (
                  <Modal isOpen={true} onClose={handleCloseUserMenu}>
                    {!buyerMode && !isMobile && (
                      <MenuItem
                        component={Link}
                        to={routes.profile.path}
                        onClick={handleCloseUserMenu}
                      >
                        Update Profile
                      </MenuItem>
                    )}
                    {user?.isRetailUser &&
                      !isMobile &&
                      !buyerMode &&
                      user?.userRole !== "sysadmin" && (
                        <MenuItem onClick={handleActivateBuyerMode}>
                          Switch to Buyer Mode
                        </MenuItem>
                      )}
                    {user?.isRetailUser && buyerMode && !isMobile && (
                      <MenuItem onClick={handleDeactivateBuyerMode}>
                        Switch to Retail Mode
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Modal>
                )}
                {!isMobile && (
                  <Menu
                    sx={{ mt: "45px", zIndex: "9999" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {!buyerMode && !isMobile && (
                      <MenuItem
                        component={Link}
                        to={routes.profile.path}
                        onClick={handleCloseUserMenu}
                      >
                        Update Profile
                      </MenuItem>
                    )}
                    {user?.isRetailUser &&
                      !isMobile &&
                      !buyerMode &&
                      user?.userRole !== "sysadmin" && (
                        <MenuItem onClick={handleActivateBuyerMode}>
                          Switch to Buyer Mode
                        </MenuItem>
                      )}
                    {user?.isRetailUser && buyerMode && !isMobile && (
                      <MenuItem onClick={handleDeactivateBuyerMode}>
                        Switch to Retail Mode
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      {showVerifyCodeModal && (
        <VerifyCodeModal
          isOpen
          obfuscatePhoneNumber
          showResend={false}
          onClose={() => {
            setShowVerifyCodeModal(false);
            handleCancelDeactivateBuyerMode();
          }}
          onSuccessfulVerification={handleSuccessfulVerification}
        />
      )}
    </>
  );
};
