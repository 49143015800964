import { OrderCardError } from "./orderCard";
import { OrderCardStatus, orderCardTypes } from "./orderCardTypes";
import { Subgroup } from "./subgroup";
import { Retailer } from "./tenant";
import { Vendor } from "./vendor";

export type SubgroupError = OrderCardError<{ subgroup: Subgroup }>;

export type OrderCardBusinessLogistics = {
  orderCardGuid: string;
  orderCardType: (typeof orderCardTypes)["businessLogistics"];
  categoryName: string;
  groupName: string;
  subgroupName: string;
  isRequired: boolean;
  isApproved: boolean;
  isHidden: boolean;
  isLocked: boolean;
  orderCardStatus: OrderCardStatus;
  sortOrder: number;
  isRetailerReadOnly: boolean;
  retailers: Retailer[];
  vendors: Vendor[];
  errors: SubgroupError[];
  cardData: {
    soldAs: "r" | "s" | "y";
    retailerTenantGuid: string | null;
    retailSalesRep: string | null;
    orderIdentifier: string;
    vinNumber: string | null;
    transportationVendorGuid: string | null;
    shipTo: "r" | "b" | "p";
  };
};

export const getShipToDisplayName = (
  shipTo: OrderCardBusinessLogistics["cardData"]["shipTo"]
): string => {
  const displayName: Record<
    OrderCardBusinessLogistics["cardData"]["shipTo"],
    string
  > = {
    r: "Retailer",
    b: "Build Site",
    p: "Pick Up",
  };
  return displayName[shipTo];
};

export const getSoldAsSummaryDisplayName = (
  soldAs: OrderCardBusinessLogistics["cardData"]["soldAs"]
): string => {
  const displayName: Record<
    OrderCardBusinessLogistics["cardData"]["soldAs"],
    string
  > = {
    r: "Retail Sold Unit",
    s: "Stock Unit",
    y: "",
  };
  return displayName[soldAs];
};
