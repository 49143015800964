import React from "react";
import { Popover as MuiPopover, Box, PopoverProps } from "@mui/material";

interface CustomPopoverProps
  extends Omit<PopoverProps, "open" | "anchorEl" | "onClose"> {
  button: React.ReactElement;
  body: React.ReactNode;
}

export const Popover: React.FC<CustomPopoverProps> = ({
  button,
  body,
  ...popoverProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {React.cloneElement(button, {
        onClick: handleClick,
      })}
      <MuiPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...popoverProps}
      >
        <Box sx={{ p: 2, maxWidth: 300, borderRadius: 2, boxShadow: 3 }}>
          {body}
        </Box>
      </MuiPopover>
    </div>
  );
};
