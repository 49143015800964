import React, { useEffect } from "react";
import { Alert, Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { OrderCardRetailerFees } from "../../types/orderCardRetailerFees";

interface AddRetailerFeeModalProps {
  isOpen: boolean;
  orderCardGuid: string;
  onSaveSuccessful: (orderCard: OrderCardRetailerFees) => void;
  onClose: () => void;
}

type FormData = {
  feeName: string;
};

const getDefaultValues = (): FormData => {
  return {
    feeName: "",
  };
};

export const AddRetailerFeeModal: React.FC<AddRetailerFeeModalProps> = ({
  isOpen,
  orderCardGuid,
  onSaveSuccessful,
  onClose,
}) => {
  const {
    data: saveFeeData,
    loading: saveFeeLoading,
    request: saveFeeRequest,
    status: saveFeeStatus,
    errorMessage: saveFeeErrorMessage,
  } = useApiRequest<OrderCardRetailerFees>(true);

  const { handleSubmit, register, formState } = useForm<FormData>({
    defaultValues: getDefaultValues(),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onSubmit = (data: FormData) => {
    saveFeeRequest(`/ordercards/${orderCardGuid}/retailerfees/add`, {
      method: "POST",
      data: data,
    });
  };

  useEffect(() => {
    if (saveFeeData && saveFeeStatus === "ok" && saveFeeLoading === false) {
      onSaveSuccessful(saveFeeData);
    }
  }, [saveFeeData, saveFeeLoading, onSaveSuccessful, saveFeeStatus]);

  return (
    <Modal heading={"Add Fee"} isOpen={isOpen} onClose={onClose}>
      {saveFeeErrorMessage && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {saveFeeErrorMessage}
        </Alert>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: { xs: "100%", sm: "552px" } }}
      >
        <InputField
          {...register("feeName")}
          label="Fee Name"
          placeholder="Enter Fee Name"
          required
          fullWidth
          error={!!formState.errors.feeName}
          helperText={formState.errors.feeName?.message}
        />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!formState.isValid}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
