import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import { EmptyState } from "../../components/EmptyState";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  MoreMenuButton,
  MoreMenuButtonProps,
} from "../../components/MoreMenuButton";
import {
  Grid,
  Stack,
  Button,
  OutlinedInput,
  InputAdornment,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { Table } from "../../components/Table";
import { Order } from "../../types/order";
import { NewTemplateModal } from "./NewTemplateModal";
import {
  getUserFullName,
  getUserInitials,
  getUserPhotoUrlSrc,
} from "../../types/user";
import { getTenantInitials, getTenantPhotoUrlSrc } from "../../types/tenant";
import { useSession } from "../../hooks/useSession";
import { generatePath, useNavigate } from "react-router-dom";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { UserRole, UserRoles } from "../../types/userRole";
import { getModelBuildCodeDisplayName } from "../../types/model";
import { formatAsDate } from "../../utils/date";
import { LoadingOverlay } from "../../components/LoadingOverlay";

const ManageTemplatesRoute: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const { user } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const isUserAdminOrOwner =
    user &&
    ([UserRole.ADMIN, UserRole.OWNER] as UserRoles[]).includes(user.userRole);

  usePageMetadata({ title: `Manage ${routes.manageDataTemplates.label}` });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataTemplates.label }]);
  }, [setBreadcrumbs]);

  const {
    data: templatesData,
    loading: templatesLoading,
    request: templatesRequest,
  } = useApiRequest<Order[]>(false);

  const {
    data: duplicateTemplateData,
    loading: duplicateTemplateLoading,
    request: duplicateTemplateRequest,
    status: duplicateTemplateStatus,
    errorMessage: duplicateTemplateErrorMessage,
  } = useApiRequest<Order>(false);

  const refreshTemplates = useCallback(() => {
    templatesRequest("/company/templates", { method: "GET" });
  }, [templatesRequest]);

  useEffect(() => {
    refreshTemplates();
  }, [refreshTemplates]);

  useEffect(() => {
    if (
      duplicateTemplateData &&
      duplicateTemplateStatus === "ok" &&
      duplicateTemplateLoading === false
    ) {
      refreshTemplates();
    }
  }, [
    duplicateTemplateData,
    duplicateTemplateLoading,
    duplicateTemplateStatus,
    refreshTemplates,
  ]);

  useEffect(() => {
    if (duplicateTemplateErrorMessage) {
      setIsLoading(false);
      showErrorToast({ message: duplicateTemplateErrorMessage });
    }
  }, [duplicateTemplateErrorMessage, showErrorToast, setIsLoading]);

  const handleNewButtonClick = () => {
    setIsCreating(true);
  };

  const handleEditClick = useCallback(
    (template: Order) => () => {
      navigate(
        generatePath(routes.quotesAndOrdersDetail.path, {
          tenantGuid: user?.tenantGuid ?? "",
          orderGuid: template.orderGuid,
        })
      );
    },
    [navigate, user?.tenantGuid]
  );

  const handleDuplicateAllClick = useCallback(
    (template: Order) => () => {
      setIsLoading(true);
      duplicateTemplateRequest(`/orders/${template.orderGuid}/duplicate`, {
        method: "GET",
      },
      {
        onSuccess: () => { setIsLoading(false); }
      }
      );
    },
    [duplicateTemplateRequest, setIsLoading]
  );

  const handleClose = useCallback(() => {
    setIsCreating(false);
  }, []);

  const handleSaveSuccessful = useCallback(
    (template: Order) => {
      navigate(
        generatePath(routes.quotesAndOrdersDetail.path, {
          tenantGuid: user?.tenantGuid ?? "",
          orderGuid: template.orderGuid,
        })
      );
    },
    [navigate, user?.tenantGuid]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const columns: GridColDef<Order>[] = useMemo(
    () => [
      {
        field: "templateName",
        headerName: "Template Name",
        cellClassName: "font-500",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "model.modelNumber",
        headerName: "Model",
        flex: 1,
        minWidth: 200,
        maxWidth: 300,
        valueGetter: (_, order) => order.model.modelName,
      },
      {
        field: "buildCode",
        headerName: "Build Code",
        flex: 0.5,
        minWidth: 156,
        maxWidth: 250,
        valueGetter: (_, order) =>
          getModelBuildCodeDisplayName(order.model.modelConstructionType),
      },
      {
        field: "retailer.tenantName",
        headerName: "Retailer",
        flex: 1,
        minWidth: 200,
        renderCell: (params) =>
          params.row.retailer ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              height="100%"
              width="100%"
            >
              <Avatar
                sx={{ mr: 2 }}
                src={getTenantPhotoUrlSrc(params.row.retailer)}
              >
                {getTenantInitials(params.row.retailer)}
              </Avatar>

              <Typography>{params.row?.retailer?.tenantName}</Typography>
            </Stack>
          ) : null,
        valueGetter: (_, order) => order.retailer,
        sortComparator: (a, b) => {
          if (!a) return 1;
          if (!b) return -1;
          return a?.tenantName.localeCompare(b?.tenantName);
        },
      },
      {
        field: "templateCreator",
        headerName: "Template Creator",
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            height="100%"
            width="100%"
          >
            <Avatar
              sx={{ mr: 2 }}
              src={getUserPhotoUrlSrc(params.row?.assigneeUser)}
            >
              {getUserInitials(params.row?.assigneeUser)}
            </Avatar>
            <Typography>{getUserFullName(params.row?.assigneeUser)}</Typography>
          </Stack>
        ),
        sortComparator: (a, b) => {
          return (
            (a?.userFirstName + a?.userLastName).localeCompare(
              b?.userFirstName + b?.userLastName
            ) || 0
          );
        },
      },
      {
        field: "updatedTimestamp",
        headerName: "Last Edited",
        flex: 0.5,
        minWidth: 120,
        maxWidth: 200,
        valueFormatter: (_, row) => {
          return formatAsDate(row.updatedTimestamp);
        },
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        align: "right",
        sortable: false,
        renderCell: ({ row }) => {
          const isCreator = row.assigneeUser.userGuid === user?.userGuid;

          const menuItems: MoreMenuButtonProps["menuItems"] = [
            {
              label: isUserAdminOrOwner || isCreator ? "Edit" : "View",
              onClick: handleEditClick(row),
            },
            {
              label: "Duplicate",
              onClick: handleDuplicateAllClick(row),
              disabled: duplicateTemplateLoading,
            },
          ];

          return <MoreMenuButton menuItems={menuItems} />;
        },
      },
    ],
    [
      user?.userGuid,
      handleEditClick,
      isUserAdminOrOwner,
      handleDuplicateAllClick,
      duplicateTemplateLoading,
    ]
  );

  const filteredTemplates = useMemo(() => {
    if (!templatesData) {
      return [];
    }

    if (searchText.length === 0) {
      return templatesData;
    }

    const filtered = templatesData.filter((template) => {
      const searchValues: string[] = [
        template.model.modelNumber,
        getModelBuildCodeDisplayName(template.model.modelConstructionType),
        getUserFullName(template.assigneeUser),
        formatAsDate(template.updatedTimestamp),
      ];

      if (template.templateName) {
        searchValues.push(template.templateName);
      }

      if (template.retailer?.tenantName) {
        searchValues.push(template.retailer.tenantName);
      }

      const matchesFilter = searchValues.some(
        (v) => v && v.toLowerCase().includes(searchText)
      );

      return matchesFilter;
    });

    return filtered;
  }, [templatesData, searchText]);

  return (
    <>
      {isLoading && <LoadingOverlay />}

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"right"}
        marginBottom={2}
      >
        <Grid item />
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewButtonClick}
            >
              + Template
            </Button>
            <OutlinedInput
              placeholder="Search"
              onChange={handleSearchChange}
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {templatesData && templatesData.length === 0 && !templatesLoading && (
        <EmptyState
          heading="No Templates Available"
          body="No templates have been created. To create a template, click the + Template button."
        />
      )}
      <Box>
        {filteredTemplates && filteredTemplates.length > 0 && (
          <Table
            columns={columns}
            rows={filteredTemplates}
            rowSelection={false}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.orderGuid}
          />
        )}
      </Box>
      {isCreating && (
        <NewTemplateModal
          isOpen={isCreating}
          onClose={handleClose}
          onSaveSuccessful={handleSaveSuccessful}
        />
      )}
    </>
  );
};

export default ManageTemplatesRoute;
