import { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Tenant } from "../../types/tenant";
import { Box, InputAdornment, OutlinedInput, Stack } from "@mui/material";
import { Table } from "../../components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { PageHeader } from "../../components/PageHeader";
import { Search } from "@mui/icons-material";
import { NewEditModal } from "./NewEditModal";
import { MenuButton } from "../../components/MenuButton";
import { EmptyState } from "../../components/EmptyState";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { useSession } from "../../hooks/useSession";
import { routes } from "../../routes";
import { generatePath, useNavigate } from "react-router-dom";
import { Switch } from "../../components/Switch";

const ManageTenantsRoute: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [newType, setNewType] = useState<Tenant["tenantType"]>("m");
  const [isAssigningOwner, setIsAssigningOwner] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);

  const [searchText, setSearchText] = useState("");

  const { impersonate, user } = useSession();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { setGlobalToastNotification } = useGlobalToastNotificationContext();
  const navigate = useNavigate();

  usePageMetadata({ title: `Manage ${routes.manageTenants.label}` });

  // This protects the user from hitting the back button after impersonating
  useEffect(() => {
    if (user?.userRole !== "admin" && user?.userRole !== "sysadmin") {
      navigate(routes.home.path);
    }
  }, [user, navigate]);

  const {
    data: tenants,
    loading,
    request: tenantRequest,
  } = useApiRequest<Tenant[]>(false);
  const {
    request: inviteRequest,
    status: sendInviteStatus,
    loading: sendInviteLoading,
    error: sendInviteError,
  } = useApiRequest();
  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  const refreshTenants = useCallback(() => {
    tenantRequest("/tenants", {
      method: "GET",
    });
  }, [tenantRequest]);

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageTenants.label }]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    refreshTenants();
  }, [refreshTenants]);

  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok")
      refreshTenants();
  }, [activationToggleStatus, refreshTenants, activateLoading]);

  const filteredTenants = useMemo(() => {
    if (!tenants) return [];
    return tenants.filter(
      (tenant) =>
        tenant.tenantName.toLowerCase().includes(searchText) ||
        tenant.ownerUser?.userFirstName.toLowerCase().includes(searchText) ||
        tenant.ownerUser?.userLastName.toLowerCase().includes(searchText) ||
        tenant.mfgNameWhoCanEdit?.toLowerCase().includes(searchText)
    );
  }, [tenants, searchText]);

  const handleSave = useCallback(() => {
    refreshTenants();
    setIsEditing(false);
  }, [refreshTenants]);

  const handleNewButtonClick = useCallback(
    (type: Tenant["tenantType"], tenant: Tenant | null = null) =>
      () => {
        setSelectedTenant(tenant);
        setIsEditing(true);
        setIsAssigningOwner(tenant !== null);
        setNewType(type);
      },
    [setIsEditing, setNewType, setSelectedTenant]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const handleActiveToggle = useCallback(
    (tenant: Tenant) => () => {
      activationToggleRequest(`/tenants/${tenant.tenantGuid}/activate`, {
        method: "POST",
        data: {
          isActive: !tenant.isActive,
        },
      });
    },
    [activationToggleRequest]
  );

  const handleImpersonate = useCallback(
    (tenant: Tenant) => () => {
      impersonate(tenant);
    },
    [impersonate]
  );

  useEffect(() => {
    if (sendInviteLoading === false && sendInviteStatus === "ok") {
      setGlobalToastNotification({
        severity: "success",
        message: "Invite Sent!",
        title: "Success",
      });
    } else if (sendInviteError != null) {
      setGlobalToastNotification({
        severity: "error",
        message: "Something went wrong. Please try again..",
        title: "Error",
      });
    }
  }, [
    sendInviteStatus,
    sendInviteLoading,
    setGlobalToastNotification,
    sendInviteError,
  ]);

  const handleInviteRequest = useCallback(
    (tenant: Tenant) => () => {
      inviteRequest("/users/invite", {
        method: "POST",
        data: {
          userGuid: tenant.ownerUser?.userGuid,
        },
      });
    },
    [inviteRequest]
  );

  const columns: GridColDef<Tenant>[] = useMemo(
    () => [
      { field: "tenantName", headerName: "Name", width: 700 },
      {
        field: "tenantType",
        headerName: "Type",
        flex: 0.5,
        minWidth: 140,
        renderCell: (params) =>
          params.value === "m" ? "Manufacturer" : "Retailer",
      },
      {
        field: "ownerUser",
        headerName: "Owner",
        flex: 0.75,
        minWidth: 250,
        renderCell: (params) => {
          if (params.row.ownerUser === null) {
            return <i>{params.value} can edit</i>;
          }
          return params.value;
        },
        valueGetter: (_, row) => {
          if (row.ownerUser === null) {
            return row.mfgNameWhoCanEdit;
          }
          return row.ownerUser.userFirstName + " " + row.ownerUser.userLastName;
        },
      },
      {
        field: "isActive",
        headerName: "Active",
        renderCell: (params) => {
          if (!params.row.ownerUser) {
            return null;
          }
          return (
            <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
              <Switch
                checked={params.row.isActive}
                onChange={handleActiveToggle(params.row)}
              />
            </Box>
          );
        },
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        renderCell: ({ row: tenant }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Impersonate",
                  onClick: handleImpersonate(tenant),
                },
                {
                  label: "Send Invite",
                  onClick: handleInviteRequest(tenant),
                },
                ...(!tenant.ownerUser
                  ? [
                      {
                        label: "Assign Owner",
                        onClick: handleNewButtonClick("r", tenant),
                      },
                    ]
                  : []),
              ]}
            />
          );
        },
      },
    ],
    [
      handleActiveToggle,
      handleInviteRequest,
      handleImpersonate,
      handleNewButtonClick,
    ]
  );

  return (
    <>
      <Box>
        <PageHeader
          title="Manufacturers & Retailers"
          actions={
            <Stack spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <MenuButton
                  menuItems={[
                    {
                      label: "Add Manufacturer",
                      onClick: handleNewButtonClick("m"),
                    },
                    {
                      label: "Add Retailer",
                      onClick: handleNewButtonClick("r"),
                    },
                  ]}
                >
                  New Tenant
                </MenuButton>
              </Box>
              <OutlinedInput
                placeholder="Search"
                onChange={handleSearchChange}
                size="small"
                color="primary"
                sx={{ backgroundColor: "white" }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </Stack>
          }
        />
        {tenants && tenants.length === 0 && !loading && (
          <EmptyState
            heading="No Tenants"
            body="To add a manufacturer or tenant to the system, click the New Tenant button and select which tenant type you would like to create."
          />
        )}
        {tenants && tenants.length > 0 && (
          <Table
            columns={columns}
            rows={filteredTenants}
            rowSelection={false}
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.tenantGuid}
            getRowClassName={(params) => {
              return params.row.ownerUser
                ? ""
                : `MuiDataGrid-row--not-clickable`;
            }}
            sx={{
              "& .MuiDataGrid-row.MuiDataGrid-row--not-clickable:hover": {
                cursor: "initial",
              },
            }}
            onRowClick={({ row }) => {
              if (row.ownerUser) {
                navigate(
                  generatePath(routes.tenantUsers.path, {
                    tenantGuid: row.tenantGuid,
                  })
                );
              }
            }}
          />
        )}
      </Box>
      {isEditing && (
        <NewEditModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSaveSuccessful={handleSave}
          tenant={selectedTenant}
          isNew={!selectedTenant}
          newType={newType}
          isAssigningOwner={isAssigningOwner}
        />
      )}
    </>
  );
};

export default ManageTenantsRoute;
