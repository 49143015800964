import React, { useEffect } from "react";
import { Alert, Box, Button, Stack } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Modal } from "../../components/Modal";
import InputField from "../../components/InputField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { ConstructionGroup } from "../../types/constructionGroup";
import {
  ConstructionSubgroup,
  subgroupTypeOptions,
} from "../../types/constructionSubgroup";
import SelectField from "../../components/SelectField";

type FormData = {
  subgroupName: string;
  subgroupType: "rd" | "od" | "rh" | "";
};

type NewEditSubgroupModalProps = {
  isOpen: boolean;
  onSaveSuccessful: () => void;
  onClose: () => void;
  group: ConstructionGroup;
  subgroup?: ConstructionSubgroup;
  isNew: boolean;
};

const getDefaultValues = (subgroup?: ConstructionSubgroup): FormData => {
  return {
    subgroupName: subgroup?.subgroupName ?? "",
    subgroupType: subgroup?.subgroupType ?? "",
  };
};

export const NewEditSubgroupModal: React.FC<NewEditSubgroupModalProps> = ({
  isOpen,
  onSaveSuccessful,
  onClose,
  group,
  subgroup,
  isNew,
}) => {
  const {
    data: saveGroupData,
    loading: saveGroupLoading,
    request: saveGroupRequest,
    status: saveGroupStatus,
    error: saveGroupError,
  } = useApiRequest<ConstructionSubgroup>(true);

  const { register, handleSubmit, formState, control } = useForm<FormData>({
    defaultValues: getDefaultValues(subgroup),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    if (isNew) {
      saveGroupRequest(
        `retailer/constoptions/groups/${group.constructionGroupGuid}/subgroups/add`,
        {
          method: "POST",
          data,
        }
      );
    } else {
      saveGroupRequest(
        `/retailer/constoptions/subgroups/${subgroup?.constructionSubgroupGuid}/edit`,
        {
          method: "POST",
          data,
        }
      );
    }
  };

  useEffect(() => {
    if (
      saveGroupData &&
      saveGroupStatus === "ok" &&
      saveGroupLoading === false
    ) {
      onSaveSuccessful();
    }
  }, [saveGroupData, onSaveSuccessful, saveGroupStatus, saveGroupLoading]);

  return (
    <Modal
      heading={isNew ? "New Sub Group" : "Edit Sub Group"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "371px" } }}>
        {saveGroupError && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {saveGroupError.response?.status === 409
              ? "A subgroup already exists with this name."
              : "Something went wrong. Please try again."}
          </Alert>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3}>
          <InputField
            label="Sub Group Name"
            placeholder="Enter name"
            fullWidth
            required
            {...register("subgroupName", { required: "Enter name" })}
            error={!!formState.errors.subgroupName}
            helperText={formState.errors.subgroupName?.message}
          />
          <Controller
            name="subgroupType"
            control={control}
            rules={{ required: "Enter State" }}
            render={({ field }) => (
              <SelectField
                label="Type"
                placeholder="Select"
                options={subgroupTypeOptions}
                {...field}
              />
            )}
          />
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={saveGroupLoading}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
