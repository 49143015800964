import React, { useEffect, useMemo, useState } from "react";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Order } from "../../types/order";
import { OrderCardActionButton } from "./OrderCardActionButton";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  orderCardSubgroupOptionHasLogicErrors,
  type OrderCardSubgroupOption as OrderCardSubgroupOptionType,
} from "../../types/orderCardSubgroupOption";
import { OrderCardBase } from "./OrderCardBase";
import InputField from "../../components/InputField";
import SelectField, { SelectFieldProps } from "../../components/SelectField";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  getOptionOptionRoomOptions,
  getOptionPricePerShortDisplayName,
  isOptionNonRoomOption,
  Option,
} from "../../types/option";
import { useOrderCard } from "./OrderCardContext";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import MultiSelectField from "../../components/MultiSelectField";
import { OrderCardSubgroupOptionAttributeField } from "./OrderCardSubgroupOptionAttributeField";
import { getOptionRoomOptions, OptionRoom } from "../../types/optionRoom";
import { formatAsCurrency } from "../../utils/number";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { OrderCardApproveDenyButtons } from "./OrderCardApproveDenyButtons";
import { ConfirmModal } from "../../components/ConfirmModal";
import { Attribute } from "../../types/attribute";
import { AttributeSelection } from "../../types/attributeSelection";
import { getOrderCardError } from "../../types/orderCard";
import { formatAsDate } from "../../utils/date";
import { applyLogicToOptions } from "../../types/logicResult";
import { DeepPartial } from "../../utils/deepPartial";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCard } from "../../types/orderCard";

const CustomOptionSelectOption = {
  label: "Custom Option",
  value: "Custom Option",
};

type FormData = {
  customOptionSelected: boolean;
  selectedOptionGuid: string | null;
  quantities: { quantity: number }[];
  totalQuantity: number;
  selectedOptionRooms: OptionRoom[];
  attributeSelections: Record<
    Attribute["attributeGuid"],
    AttributeSelection["attributeselectionGuid"]
  >;
  notes: string | null;
  price: number | null;
  color: string | null;
};

const getDefaultValues = (
  orderCard: DeepPartial<OrderCardSubgroupOptionType> | null
): FormData => {
  const customOptionSelected =
    orderCard?.cardData?.customOptionSelected ?? false;

  const selectedOptionGuid = customOptionSelected
    ? CustomOptionSelectOption.value
    : orderCard?.cardData?.selectedOptionGuid ?? "";

  const selectedOption = orderCard?.selectionOptions?.find(
    (option) => option.optionGuid === selectedOptionGuid
  );

  // Set price field value
  const price = customOptionSelected
    ? orderCard?.cardData?.price ?? 0
    : selectedOption?.optionPricing[0].price ?? 0;

  // Set quantities/totalQuantity field values
  let quantities: number[] | undefined = orderCard?.cardData?.quantities;
  if (
    (quantities ?? []).length === 0 &&
    selectedOption &&
    selectedOption.optionMinimum === selectedOption.optionMaximum &&
    (isOptionNonRoomOption(selectedOption) ||
      selectedOption.optionPricedPer !== "e")
  ) {
    quantities = [selectedOption.optionMinimum];
  }

  return {
    customOptionSelected,
    selectedOptionGuid,
    quantities: quantities?.map((quantity) => ({ quantity })) ?? [],
    totalQuantity: (quantities ?? []).reduce(
      (sum, quantity) => sum + quantity,
      0
    ),
    selectedOptionRooms: orderCard?.cardData?.selectedOptionRooms ?? [],
    attributeSelections: (
      orderCard?.cardData?.attributeSelections ?? []
    ).reduce((curr, attributeSelection) => {
      const key = attributeSelection.attributeGuid;
      if (!curr[key]) {
        curr[key] = attributeSelection.attributeselectionGuid;
      }
      return curr;
    }, {} as FormData["attributeSelections"]),
    notes: orderCard?.cardData?.notes ?? "",
    price,
    color: orderCard?.cardData?.color ?? null,
  };
};

interface OrderCardSubgroupOptionProps {
  order: Order;
  orderCard: OrderCardSubgroupOptionType;
  expanded: boolean;
  groupIndex: number;
  refreshOrder: () => void;
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
}

export const OrderCardSubgroupOption: React.FC<
  OrderCardSubgroupOptionProps
> = ({ order, orderCard, expanded, refreshOrder, groupIndex, onOrderCardEdited }) => {
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const [pendingDenial, setPendingDenial] = useState(false);
  const [groupInvalid, setGroupInvalid] = useState(false);
  const [subgroupError, setSubgroupError] = useState<string | undefined>(
    undefined
  );
  const [seriesError, setSeriesError] = useState<Option | undefined>(undefined);
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const {
    status: addCustomOptionStatus,
    loading: addCustomOptionLoading,
    request: addCustomOptionRequest,
    errorMessage: addCustomOptionErrorMessage,
  } = useApiRequest<OrderCardSubgroupOptionType>();

  const {
    status: addRoomStatus,
    loading: addRoomLoading,
    request: addRoomRequest,
    errorMessage: addRoomErrorMessage,
  } = useApiRequest();

  const {
    data: deleteOrderCardData,
    status: deleteOrderCardStatus,
    loading: deleteOrderCardLoading,
    request: deleteOrderCardRequest,
    errorMessage: deleteOrderCardErrorMessage,
  } = useApiRequest();

  const handleAddCustomOptionClick = () => {
    addCustomOptionRequest(`/orders/${order.orderGuid}/subgroups/add`, {
      method: "POST",
      data: {
        groupGuid: orderCard.groupGuid,
      },
    });
  };

  const {
    control,
    register,
    reset,
    formState,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const selectedOptionRooms = watch("selectedOptionRooms");
  const quantities = watch("quantities");
  const selectedOptionGuid = watch("selectedOptionGuid");
  const customOptionSelected = watch("customOptionSelected");

  const quantityFields = useFieldArray({
    control,
    name: "quantities",
  });

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  useEffect(() => {
    if (addCustomOptionStatus === "ok") {
      refreshOrder();
    }
  }, [addCustomOptionStatus, refreshOrder]);

  useEffect(() => {
    if (addRoomStatus === "ok") {
      refreshOrder();
    }
  }, [addRoomStatus, refreshOrder]);

  useEffect(() => {
    if (addCustomOptionErrorMessage) {
      showErrorToast({ message: addCustomOptionErrorMessage });
    }
  }, [addCustomOptionErrorMessage, showErrorToast]);

  useEffect(() => {
    if (addRoomErrorMessage) {
      showErrorToast({ message: addRoomErrorMessage });
    }
  }, [addRoomErrorMessage, showErrorToast]);

  useEffect(() => {
    if (deleteOrderCardData && deleteOrderCardStatus === "ok") {
      refreshOrder();
      setPendingDenial(false);
    }
  }, [deleteOrderCardData, deleteOrderCardStatus, refreshOrder]);

  useEffect(() => {
    if (deleteOrderCardErrorMessage) {
      showErrorToast({ message: deleteOrderCardErrorMessage });
      setPendingDenial(false);
    }
  }, [deleteOrderCardErrorMessage, showErrorToast]);

  useEffect(() => {
    if (orderCard.errors.length === 0 || disabled) {
      return;
    }

    const seriesError = getOrderCardError(orderCard, "esoos");

    setSeriesError(seriesError?.option);

    const groupError = getOrderCardError(orderCard, "esog");

    setGroupInvalid(!!groupError);

    const subgroupError = getOrderCardError(orderCard, "esos");

    const minQuantityError = getOrderCardError(orderCard, "esmq");
    const maxQuantityError = getOrderCardError(orderCard, "esxq");

    if (!!subgroupError) {
      setSubgroupError(`Exp ${formatAsDate(subgroupError.subgroup.endDate)}`);
    } else {
      setSubgroupError(undefined);
    }

    if (!!minQuantityError) {
      setError("totalQuantity", {
        type: "manual",
        message: `${minQuantityError} Min`,
      });
    }

    if (!!maxQuantityError) {
      setError("totalQuantity", {
        type: "manual",
        message: `${maxQuantityError} Max`,
      });
    }

    const subgroupOptionError = getOrderCardError(orderCard, "esoo");

    if (subgroupOptionError?.option?.endDate) {
      setError("selectedOptionGuid", {
        type: "manual",
        message: `Exp ${formatAsDate(subgroupOptionError.option.endDate)}`,
      });
    }
  }, [disabled, orderCard, setError]);

  // Keep totalQuantity update to date.
  const totalQuantityNew = quantities.reduce(
    (sum, quantity) => sum + quantity.quantity,
    0
  );
  useEffect(() => {
    setValue("totalQuantity", totalQuantityNew || 0);
  }, [totalQuantityNew, setValue]);

  const selectedOption = useMemo(() => {
    return orderCard.selectionOptions.find(
      (option) => option.optionGuid === selectedOptionGuid
    );
  }, [orderCard.selectionOptions, selectedOptionGuid]);

  const attributes: Attribute[] = useMemo(() => {
    const selectedOptionAttributes = selectedOption?.attributes ?? [];
    const invalidAttribute = getOrderCardError(orderCard, "esoar")?.attribute;
    return invalidAttribute
      ? [invalidAttribute, ...selectedOptionAttributes]
      : selectedOptionAttributes;
  }, [orderCard, selectedOption]);

  const optionsFieldOptions: SelectFieldProps["options"] = useMemo(() => {
    const logicResult = applyLogicToOptions(
      orderCard.selectionOptions,
      selectedOptionGuid,
      order.logicAffected
    );

    const options: SelectFieldProps["options"] = [
      ...logicResult.entitiesWithLogicErrors.map((option) =>
        getOptionOption(option, "(Logic Error)")
      ),
      ...logicResult.entitiesWithoutLogicErrors.map((option) =>
        getOptionOption(option)
      ),

      ...(!!seriesError ? [getOptionOption(seriesError, "Invalid")] : []),
    ];

    if (orderCard.customOptionAllowed) {
      options.push(CustomOptionSelectOption);
    }

    return options;
  }, [
    order.logicAffected,
    orderCard.customOptionAllowed,
    orderCard.selectionOptions,
    selectedOptionGuid,
    seriesError,
  ]);

  const roomsFieldOptions = useMemo(() => {
    const invalidRooms =
      getOrderCardError(orderCard, "esor")?.invalidRooms ?? [];

    const withError = getOptionRoomOptions(invalidRooms).map((option) => ({
      ...option,
      error: "Invalid",
    }));

    return customOptionSelected
      ? getOptionRoomOptions(orderCard.rooms ?? [])
      : [...withError, ...getOptionOptionRoomOptions(selectedOption)];
  }, [orderCard, customOptionSelected, selectedOption]);

  const isOrderCardRequired = useMemo(() => {
    const isRequired =
      orderCard.isRequired ||
      order.logicAffected?.requireSubgroups.some(
        (requiredSubgroupGuid) =>
          requiredSubgroupGuid === orderCard.subgroupGuid
      );

    return isRequired;
  }, [
    order.logicAffected?.requireSubgroups,
    orderCard.isRequired,
    orderCard.subgroupGuid,
  ]);

  const isNonRoomOptionSelected = useMemo(
    () => isOptionNonRoomOption(selectedOption),
    [selectedOption]
  );

  const isQuantityByRoomOption = useMemo(
    () => selectedOption != null && selectedOption.optionPricedPer === "e",
    [selectedOption]
  );

  const isPriceFieldDisabled = useMemo(
    () => !customOptionSelected,
    [customOptionSelected]
  );

  const isRoomsDropdownVisible = useMemo(
    () =>
      customOptionSelected ||
      (selectedOption != null && !isNonRoomOptionSelected),
    [customOptionSelected, isNonRoomOptionSelected, selectedOption]
  );

  const isQuantityByRoomFieldsVisible = useMemo(
    () =>
      isQuantityByRoomOption &&
      isRoomsDropdownVisible &&
      selectedOptionRooms.length > 0,
    [isQuantityByRoomOption, isRoomsDropdownVisible, selectedOptionRooms.length]
  );

  const isQuantityFieldVisible = useMemo(
    () => selectedOption != null || customOptionSelected,
    [customOptionSelected, selectedOption]
  );

  const isQuantityFieldDisabled = useMemo(
    () =>
      !customOptionSelected &&
      (selectedOption == null ||
        selectedOption.optionMinimum === selectedOption.optionMaximum ||
        (isQuantityByRoomOption && isRoomsDropdownVisible)),
    [
      customOptionSelected,
      isRoomsDropdownVisible,
      isQuantityByRoomOption,
      selectedOption,
    ]
  );

  const isAttributesFieldsVisible = useMemo(
    () => !customOptionSelected,
    [customOptionSelected]
  );

  const isColorFieldVisible = useMemo(
    () => customOptionSelected,
    [customOptionSelected]
  );

  const isAddSubgroupButtonVisible = useMemo(
    () =>
      !disabled &&
      !customOptionSelected &&
      selectedOption?.uniqueAttributesPerRoom === true,
    [disabled, customOptionSelected, selectedOption?.uniqueAttributesPerRoom]
  );

  const isAddCustomOptionButtonVisible = orderCard.groupGuid && !disabled;

  const orderCardTotal = useMemo(() => {
    const totalPrice = orderCard.totalPrice ?? 0;
    const totalFormatted = formatAsCurrency(totalPrice);
    return totalPrice > 0 ? `+${totalFormatted}` : totalFormatted;
  }, [orderCard.totalPrice]);

  const hasLogicError = useMemo(
    () => orderCardSubgroupOptionHasLogicErrors(order, orderCard),
    [order, orderCard]
  );

  const optionPricedPer = useMemo(() => {
    if (selectedOption) {
      return selectedOption.optionPricedPer;
    }

    const pricedPerValues = orderCard.selectionOptions.map(
      (o) => o.optionPricedPer
    );
    const uniquePricedPerValues = Array.from(new Set(pricedPerValues));
    if (uniquePricedPerValues.length === 1) {
      return uniquePricedPerValues[0];
    }
    return null;
  }, [orderCard.selectionOptions, selectedOption]);

  const getOrderCardData = (
    formData: FormData
  ): OrderCardSubgroupOptionType["cardData"] => {
    const { totalQuantity, ...values } = formData;

    const attributeSelections = Object.keys(values.attributeSelections)
      .map((attributeGuid) => {
        return {
          attributeGuid: attributeGuid,
          attributeselectionGuid: values.attributeSelections[attributeGuid],
        };
      })
      .filter((selection) => selection.attributeselectionGuid != null);

    let quantities: number[] = [];
    if (isQuantityByRoomFieldsVisible) {
      quantities = values.quantities.map((q) => q.quantity);
    } else if (totalQuantity !== 0) {
      quantities = [totalQuantity];
    }

    const cardData: OrderCardSubgroupOptionType["cardData"] = {
      ...values,
      attributeSelections,
      quantities,
    };

    if (cardData.customOptionSelected) {
      cardData.selectedOptionGuid = null;
    } else {
      cardData.price = null;
      cardData.color = null;
    }

    return cardData;
  };

  const handleBlurField = () => {
    const cardData = getOrderCardData(getValues());
    saveOrderCard(orderCard.orderCardGuid, cardData);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    const cardData = getOrderCardData(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, cardData);
  };

  const handleDeleteClick = () => {
    setPendingDeletion(true);
  };

  const handleDeleteConfirmation = () => {
    refreshOrder();
  };

  const handleDeleteOrderCard = () => {
    if (orderCard.isDeletable) {
      deleteOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/delete`, {
        method: "DELETE",
      });
    } else {
      handleClearClick();
      setPendingDenial(false);
    }
  };

  const handleDenyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingDenial(true);
  };

  const handleSubgroupClick = () => {
    addRoomRequest(`/ordercards/${orderCard.orderCardGuid}/add`, {
      method: "POST",
      data: {
        selectedOptionGuid: selectedOptionGuid,
      },
    });
  };

  return (
    <>
      <OrderCardBase
        disabled={disabled}
        orderCard={orderCard}
        disclaimerText={selectedOption?.optionDisclaimer}
        subtitle={orderCardTotal}
        groupError={groupInvalid ? "Inactive" : undefined}
        subgroupError={subgroupError}
        chipLabel={customOptionSelected ? "Custom" : undefined}
        isRequired={isOrderCardRequired}
        hasLogicError={hasLogicError}
        onClearClick={handleClearClick}
        onDeleteClick={orderCard.isDeletable ? handleDeleteClick : undefined}
        expanded={expanded}
        groupIndex={groupIndex}
        action={
          isAddCustomOptionButtonVisible && (
            <OrderCardActionButton
              onClick={handleAddCustomOptionClick}
              disabled={addCustomOptionLoading}
            >
              Custom Option
            </OrderCardActionButton>
          )
        }
        endActions={
          customOptionSelected &&
          order.isTemplate === false &&
          orderCard.orderCardStatus === "c" &&
          orderCard.isApproved === false ? (
            <OrderCardApproveDenyButtons
              orderCard={orderCard}
              refreshOrder={refreshOrder}
              denyButtonText={orderCard.isDeletable ? "Delete" : "Deny"}
              onDenyClick={handleDenyClick}
              onOrderCardEdited={onOrderCardEdited}
            />
          ) : undefined
        }
      >
        <Grid container spacing={2} component="form">
          <Grid
            xs={isQuantityFieldVisible ? 6 : 9}
            sm={isQuantityFieldVisible ? 6 : 9}
            md={isQuantityFieldVisible ? 6 : 9}
          >
            <Controller
              control={control}
              name="selectedOptionGuid"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Options"
                  onFocus={handleEditingField}
                  placeholder="Enter Options"
                  fullWidth
                  disabled={disabled}
                  options={optionsFieldOptions}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onBlur={() => {
                    handleBlurField();
                    field.onBlur();
                  }}
                  onChange={(e) => {
                    const newSelectedOptionGuid = e.target.value as string;

                    const isCustomOptionSelected =
                      newSelectedOptionGuid === CustomOptionSelectOption.value;

                    const updatedOrderCard: DeepPartial<OrderCardSubgroupOptionType> =
                      {
                        ...orderCard,
                        cardData: {
                          customOptionSelected: isCustomOptionSelected,
                          selectedOptionGuid: isCustomOptionSelected
                            ? null
                            : newSelectedOptionGuid,
                        },
                      };
                    const defaultValues = getDefaultValues(updatedOrderCard);
                    reset(defaultValues);
                  }}
                />
              )}
            />
          </Grid>
          {isQuantityFieldVisible ? (
            <Grid xs={3} sm={3} md={3}>
              <InputField
                {...register(`totalQuantity`, {
                  onBlur: handleBlurField,
                  valueAsNumber: true,
                  min: 0,
                })}
                label="Quantity"
                helperText={formState.errors["totalQuantity"]?.message}
                error={!!formState.errors["totalQuantity"]?.message}
                onFocus={handleEditingField}
                placeholder="Enter Quantity"
                required={customOptionSelected}
                fullWidth
                disabled={disabled || isQuantityFieldDisabled}
                type="number"
                endAdornment={
                  selectedOption &&
                  (selectedOption.optionPricedPer === "l" ||
                    selectedOption.optionPricedPer === "s") ? (
                    <InputAdornment position="end">
                      {`${getOptionPricePerShortDisplayName(
                        selectedOption.optionPricedPer
                      )}`}
                    </InputAdornment>
                  ) : undefined
                }
                intOnly={true}
              />
            </Grid>
          ) : null}
          <Grid xs={3} sm={3} md={3}>
            <InputField
              {...register(`price`, {
                onBlur: handleBlurField,
                valueAsNumber: true,
              })}
              label="Price"
              onFocus={handleEditingField}
              placeholder=""
              required={customOptionSelected}
              fullWidth
              disabled={disabled || isPriceFieldDisabled}
              type="number"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              intOnly={true}
              endAdornment={
                optionPricedPer && optionPricedPer !== "u" ? (
                  <InputAdornment position="end">
                    {`/${getOptionPricePerShortDisplayName(optionPricedPer)}`}
                  </InputAdornment>
                ) : undefined
              }
            />
          </Grid>
          {isRoomsDropdownVisible ? (
            <Grid xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="selectedOptionRooms"
                render={({ field, fieldState }) => (
                  <MultiSelectField
                    {...field}
                    label="Rooms"
                    onFocus={handleEditingField}
                    placeholder="Enter Rooms"
                    fullWidth
                    options={roomsFieldOptions}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    onChange={(e) => {
                      const prevSelectedOptionRooms = field.value;
                      const newSelectedOptionRooms =
                        typeof e.target.value === "string"
                          ? [e.target.value as OptionRoom]
                          : e.target.value;

                      // Make sure the order of the selected room options stored in
                      // react hook form are the same order in which they appear on the UI.
                      const newSelectedOptionRoomsSorted = roomsFieldOptions
                        .map((option) => option.value)
                        .filter((roomOption) =>
                          newSelectedOptionRooms.includes(roomOption)
                        );

                      const isRoomRemoved =
                        prevSelectedOptionRooms.length >
                        newSelectedOptionRoomsSorted.length;

                      if (isRoomRemoved) {
                        const removedIndex = prevSelectedOptionRooms.findIndex(
                          (prevRoom) => {
                            const isRemoved =
                              !newSelectedOptionRooms.includes(prevRoom);
                            return isRemoved;
                          }
                        );
                        quantityFields.remove(removedIndex);
                      } else {
                        const addedIndex =
                          newSelectedOptionRoomsSorted.findIndex((newRoom) => {
                            const isAdded =
                              !prevSelectedOptionRooms.includes(newRoom);
                            return isAdded;
                          });
                        quantityFields.insert(addedIndex, { quantity: 0 });
                      }

                      field.onChange(newSelectedOptionRoomsSorted);
                    }}
                    onDelete={(_, removedIndex) => {
                      const newSelectedOptionRooms = selectedOptionRooms.filter(
                        (_, roomIndex) => roomIndex !== removedIndex
                      );
                      setValue("selectedOptionRooms", newSelectedOptionRooms);
                      quantityFields.remove(removedIndex);
                    }}
                    onBlur={() => {
                      handleBlurField();
                      field.onBlur();
                    }}
                    disabled={disabled}
                  />
                )}
              />
            </Grid>
          ) : null}

          {isAddSubgroupButtonVisible ? (
            <Grid xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                sx={{ alignSelf: "flex-start" }}
                disabled={addRoomLoading}
                onClick={handleSubgroupClick}
              >
                {orderCard.subgroupName}
              </Button>
            </Grid>
          ) : null}
          {isQuantityByRoomFieldsVisible ? (
            <Grid xs={12} sm={12} md={12}>
              <Box>
                <FormControl fullWidth size="small">
                  <FormLabel component="legend">Quantity By Room</FormLabel>
                </FormControl>
                <Grid container spacing={2}>
                  {quantityFields.fields.map((field, index) => {
                    return (
                      <Grid key={field.id} xs={12} sm={12} md={6}>
                        <FormControlLabel
                          control={
                            <InputField
                              {...register(`quantities.${index}.quantity`, {
                                onBlur: handleBlurField,
                                valueAsNumber: true,
                              })}
                              type="number"
                              fullWidth
                              disabled={disabled}
                              error={
                                !!formState.errors.quantities?.[index]?.quantity
                              }
                              helperText={
                                formState.errors.quantities?.[index]?.quantity
                                  ?.message
                              }
                              sx={{ width: "80px" }}
                              formControlSx={{ marginBottom: 0 }}
                              intOnly={true}
                            />
                          }
                          label={selectedOptionRooms[index]}
                          labelPlacement="start"
                          sx={{
                            border: "1px solid",
                            borderColor: "grey.300",
                            borderRadius: 2,
                            paddingX: 2,
                            paddingY: 1,
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: 0,
                            boxSizing: "border-box",
                            width: "100%",
                            "& .MuiFormControlLabel-label": {
                              flexGrow: 1,
                            },
                            "& .MuiFormControl-root": {
                              width: "auto",
                              flexShrink: 0,
                            },
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          ) : null}
          {isAttributesFieldsVisible &&
            attributes.map((attribute) => {
              return (
                <Grid key={attribute.attributeGuid} xs={12} sm={12} md={12}>
                  <Controller
                    control={control}
                    name={`attributeSelections.${attribute.attributeGuid}`}
                    render={({ field }) => {
                      return (
                        <OrderCardSubgroupOptionAttributeField
                          {...field}
                          orderCard={orderCard}
                          attribute={attribute}
                          errors={orderCard.errors}
                          value={field.value ?? ""}
                          onBlur={() => {
                            handleBlurField();
                            field.onBlur();
                          }}
                          onChange={(value) => {
                            field.onChange(value);
                            handleBlurField();
                          }}
                          disabled={disabled}
                          logicAffected={order.logicAffected}
                        />
                      );
                    }}
                  />
                </Grid>
              );
            })}
          {isColorFieldVisible ? (
            <Grid xs={12} sm={12} md={12}>
              <InputField
                {...register("color", { onBlur: handleBlurField })}
                label="Color"
                onFocus={handleEditingField}
                placeholder="Enter Color"
                fullWidth
                disabled={disabled}
                error={!!formState.errors.color}
                helperText={formState.errors.color?.message}
              />
            </Grid>
          ) : null}
          <Grid xs={12} sm={12} md={12}>
            <InputField
              {...register("notes", { onBlur: handleBlurField })}
              label="Notes"
              onFocus={handleEditingField}
              placeholder="Enter Notes"
              fullWidth
              required={customOptionSelected}
              disabled={disabled}
              type="text"
              minRows={1}
              multiline
              error={!!formState.errors.notes}
              helperText={formState.errors.notes?.message}
            />
          </Grid>
        </Grid>
      </OrderCardBase>
      {pendingDeletion && (
        <ConfirmDeleteModal
          orderCard={orderCard}
          action={"delete"}
          onDelete={handleDeleteConfirmation}
          heading={`Delete ${orderCard.subgroupName}?`}
          body={
            <Typography variant="body2" color="grey.500">
              Deleting this cannot be undone. Adding it back will have to be
              through a custom option.
            </Typography>
          }
          onCancel={() => {
            setPendingDeletion(false);
          }}
        />
      )}
      {pendingDenial && (
        <ConfirmModal
          isOpen={true}
          heading={
            orderCard.isDeletable
              ? "Delete this option?"
              : "Deny this custom option?"
          }
          isDestructive
          confirmDisabled={deleteOrderCardLoading}
          body={
            orderCard.isDeletable ? (
              <>
                <Typography variant="body2" color="grey.500">
                  Deleting this custom option will remove it from the group,
                  resulting in its exclusion from the quote.
                </Typography>
                <br />
                <Typography variant="body2" color="grey.500">
                  Deleting this custom option cannot be undone.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" color="grey.500">
                  Denying this custom option will clear all of the Sub Group's
                  data, returning it to its default state.
                </Typography>
                <br />
                <Typography variant="body2" color="grey.500">
                  Denying this custom option cannot be undone.
                </Typography>
              </>
            )
          }
          confirmButtonText={orderCard.isDeletable ? "Delete" : "Deny"}
          onConfirm={handleDeleteOrderCard}
          onClose={() => {
            setPendingDenial(false);
          }}
        />
      )}
    </>
  );
};

const getOptionOption = (option: Option, errorText?: string) => {
  return {
    label: (
      <>
        {option.optionName}
        {!!errorText ? (
          <Typography component={"span"} color="error" marginLeft={0.5}>
            {errorText}
          </Typography>
        ) : null}
      </>
    ),
    value: option.optionGuid,
  };
};
