import { useEffect, useMemo, useState } from "react";
import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Controller, useForm } from "react-hook-form";
import { type OrderCardSalesChangeOrder as OrderCardSalesChangeOrderType } from "../../types/orderCardSalesChangeOrder";
import { OrderCardBase } from "./OrderCardBase";
import InputField from "../../components/InputField";
import MultiSelectField from "../../components/MultiSelectField";
import { OptionRoom, getOptionRoomOptions } from "../../types/optionRoom";
import { useOrderCard } from "./OrderCardContext";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";
import { OrderCardApproveDenyButtons } from "./OrderCardApproveDenyButtons";
import { getOrderCardError } from "../../types/orderCard";
import { formatAsCurrency } from "../../utils/number";
import { formatAsDate, getTimezone } from "../../utils/date";
import { OrderCardActionButton } from "./OrderCardActionButton";
import { useApiRequest } from "../../hooks/useApiRequest";
import { canAddChangeOrder, Order } from "../../types/order";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { PrintOutlined } from "@mui/icons-material";
import { printFile } from "../../utils/file";
import { OrderStatusIndicator } from "./OrderStatusIndicator";
import { useSession } from "../../hooks/useSession";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCard } from "../../types/orderCard";

interface OrderCardSalesChangeOrderProps {
  order: Order;
  orderCard: OrderCardSalesChangeOrderType;
  expanded: boolean;
  groupIndex: number;
  refreshOrder: () => void;
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
  onAddSalesChangeOrder: (orderCard: OrderCardSalesChangeOrderType) => void;
}

type FormData = {
  subgroupName: string;
  price: number | null;
  rooms: OptionRoom[];
  color: string | null;
  notes: string | null;
};

const getDefaultValues = (
  orderCard: OrderCardSalesChangeOrderType
): FormData => {
  return {
    subgroupName: orderCard?.subgroupName ?? "",
    price: orderCard?.cardData.price ?? null,
    rooms: orderCard?.cardData.rooms ?? [],
    color: orderCard?.cardData.color ?? null,
    notes: orderCard?.cardData.notes ?? null,
  };
};

export const OrderCardSalesChangeOrder: React.FC<
  OrderCardSalesChangeOrderProps
> = ({
  order,
  orderCard,
  expanded,
  groupIndex,
  refreshOrder,
  onOrderCardEdited,
  onAddSalesChangeOrder,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const [pendingDenial, setPendingDenial] = useState(false);

  const disabled = orderCard.isLocked;

  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const subgroupName = watch("subgroupName");

  const titlePlaceholderText = "Enter Change Order Title";

  const cardTitle = subgroupName !== "" ? subgroupName : titlePlaceholderText;

  const { user } = useSession();

  const cardSubtitle = useMemo(() => {
    const formattedPrice = formatAsCurrency(orderCard.totalPrice);
    return orderCard.totalPrice > 0 ? `+${formattedPrice}` : formattedPrice;
  }, [orderCard.totalPrice]);

  const roomMenuOptions = useMemo(
    () => getOptionRoomOptions(orderCard.rooms),
    [orderCard.rooms]
  );

  const {
    request: addSalesChangeOrderRequest,
    data: addSalesChangeOrderData,
    status: addSalesChangeOrderStatus,
    loading: addSalesChangeOrderLoading,
    errorMessage: addSalesChangeOrderErrorMessage,
  } = useApiRequest<OrderCardSalesChangeOrderType>();

  const {
    data: documentTokenData,
    request: documentTokenRequest,
    loading: documentTokenLoading,
    status: documentTokenStatus,
    errorMessage: documentTokenErrorMessage,
  } = useApiRequest<{ token: string; expires: number }>();

  const {
    data: documentBlobData,
    request: documentBlobRequest,
    loading: documentBlobLoading,
    errorMessage: documentBlobErrorMessage,
  } = useApiRequest<string>();

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  useEffect(() => {
    if (addSalesChangeOrderErrorMessage) {
      showErrorToast({ message: addSalesChangeOrderErrorMessage });
    }
  }, [addSalesChangeOrderErrorMessage, showErrorToast]);

  useEffect(() => {
    if (
      addSalesChangeOrderData &&
      addSalesChangeOrderStatus === "ok" &&
      addSalesChangeOrderLoading === false
    ) {
      onAddSalesChangeOrder(addSalesChangeOrderData);
    }
  }, [
    addSalesChangeOrderData,
    addSalesChangeOrderLoading,
    addSalesChangeOrderStatus,
    onAddSalesChangeOrder,
  ]);

  useEffect(() => {
    const orderCardRoomsError = getOrderCardError(orderCard, "ecor");
    const orderCardDuplicateError = getOrderCardError(orderCard, "ecod");

    if (!!orderCardDuplicateError) {
      setError("subgroupName", { type: "manual", message: "Duplicate - Must be deleted" });
    } else if (!!orderCardRoomsError) {
      setError("rooms", { type: "manual", message: "Inactive" });
    } else {
      clearErrors("rooms");
    }
  }, [disabled, orderCard, clearErrors, setError]);

  useEffect(() => {
    if (
      documentTokenData &&
      documentTokenStatus === "ok" &&
      documentTokenLoading === false
    ) {
      const token = documentTokenData.token;
      const tz = getTimezone();
      documentBlobRequest(
        `orders/${order.orderGuid}/documents?token=${token}&action=p&tz=${tz}&scg=${orderCard.orderCardGuid}`,
        {
          method: "GET",
          responseType: "blob",
        }
      );
    }
  }, [
    documentBlobRequest,
    order.orderGuid,
    orderCard.orderCardGuid,
    documentTokenData,
    documentTokenLoading,
    documentTokenStatus,
  ]);

  useEffect(() => {
    if (documentTokenErrorMessage) {
      showErrorToast({ message: documentTokenErrorMessage });
    }
  }, [showErrorToast, documentTokenErrorMessage]);

  useEffect(() => {
    if (documentBlobData && documentBlobLoading === false) {
      printFile(documentBlobData);
    }
  }, [documentBlobData, documentBlobLoading]);

  useEffect(() => {
    if (documentBlobErrorMessage) {
      showErrorToast({ message: documentBlobErrorMessage });
    }
  }, [showErrorToast, documentBlobErrorMessage]);

  const handleAddSalesChangeOrder = () => {
    addSalesChangeOrderRequest(`/orders/${order.orderGuid}/changeorders/add`, {
      method: "GET",
    });
  };

  const handleBlurField = () => {
    const { subgroupName, ...fields } = getValues();
    saveOrderCard(orderCard.orderCardGuid, fields, subgroupName);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleDenyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingDenial(true);
  };

  const handleDeleteClick = () => {
    setPendingDeletion(true);
  };

  const handleDeleteConfirmation = () => {
    refreshOrder();
  };

  const handleDeleteClose = () => {
    setPendingDeletion(false);
    setPendingDenial(false);
  };

  const handlePrintClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    documentTokenRequest("/documents/authtoken", { method: "GET" });
  };

  return (
    <>
      <OrderCardBase
        disabled={disabled}
        orderCard={orderCard}
        onDeleteClick={handleDeleteClick}
        expanded={expanded}
        groupIndex={groupIndex}
        title={cardTitle}
        subtitle={cardSubtitle}
        chipLabel="SCO"
        action={
          canAddChangeOrder(order) && !user?.isRetailUser ? (
            <OrderCardActionButton
              disabled={disabled || addSalesChangeOrderLoading}
              onClick={handleAddSalesChangeOrder}
            >
              Add Sales Change Order
            </OrderCardActionButton>
          ) : null
        }
        endActions={
          <Stack direction="row" alignItems="center" spacing={1}>
            {order.confirmedTimestamp != null && (
              <Button
                variant="contained"
                color="secondary"
                sx={{ padding: 1, minWidth: "initial" }}
                disabled={documentBlobLoading || documentTokenLoading}
                onClick={handlePrintClick}
              >
                <PrintOutlined sx={{ fontSize: "18px" }} />
              </Button>
            )}
            {orderCard.orderCardStatus === "c" &&
            orderCard.isApproved === false ? (
              <OrderCardApproveDenyButtons
                orderCard={orderCard}
                refreshOrder={refreshOrder}
                denyButtonText="Deny"
                onDenyClick={handleDenyClick}
                onOrderCardEdited={onOrderCardEdited}
              />
            ) : (
              <OrderStatusIndicator
                disabled={disabled && order.confirmedTimestamp != null}
                orderCard={orderCard}
                hasErrors={orderCard.errors.length > 0}
                onDeleteClick={handleDeleteClick}
              />
            )}
          </Stack>
        }
      >
        <Grid container spacing={2} component="form">
          <Grid xs={12} sm={12} md={9}>
            <InputField
              {...register("subgroupName", { onBlur: handleBlurField })}
              label="Sales Change Order Title"
              onFocus={handleEditingField}
              placeholder={titlePlaceholderText}
              required
              fullWidth
              error={!!errors.subgroupName}
              helperText={errors.subgroupName?.message}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <InputField
              {...register("price", {
                valueAsNumber: true,
                onBlur: handleBlurField,
              })}
              label="Price"
              onFocus={handleEditingField}
              placeholder=" Enter Price"
              required
              fullWidth
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              error={!!errors.price}
              helperText={errors.price?.message}
              type="number"
              intOnly={true}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <InputField
              label="Date Created"
              fullWidth
              disabled
              value={formatAsDate(orderCard.createdTimestamp)}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="rooms"
              render={({ field, fieldState }) => (
                <MultiSelectField
                  {...field}
                  label="Rooms"
                  onBlur={handleBlurField}
                  onFocus={handleEditingField}
                  placeholder="Select Rooms"
                  fullWidth
                  options={roomMenuOptions}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onDelete={(option) => {
                    const newOptionRooms = orderCard.cardData.rooms.filter(
                      (s) => s !== option.value
                    );
                    setValue("rooms", newOptionRooms);
                    handleBlurField();
                  }}
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <InputField
              {...register("color", { onBlur: handleBlurField })}
              label="Color"
              onFocus={handleEditingField}
              placeholder="Enter Color"
              fullWidth
              disabled={disabled}
              error={!!errors.color}
              helperText={errors.color?.message}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <InputField
              {...register("notes", { onBlur: handleBlurField })}
              fullWidth
              required
              onFocus={handleEditingField}
              label="Notes"
              placeholder="Enter notes..."
              multiline
              rows={1}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </OrderCardBase>
      {(pendingDeletion || pendingDenial) && (
        <ConfirmDeleteModal
          orderCard={orderCard}
          action={pendingDeletion ? "delete" : "deny"}
          heading={
            pendingDeletion
              ? "Delete this sales change order?"
              : "Deny this sales change order?"
          }
          body={
            <>
              <Typography variant="body2" color="grey.500">
                {pendingDeletion ? "Deleting" : "Denying"} this sales change
                order will remove it, resulting in its exclusion from the order.
              </Typography>
              <br />
              <Typography variant="body2" color="grey.500">
                {pendingDeletion ? "Deleting" : "Denying"} this sales change
                order cannot be undone.
              </Typography>
            </>
          }
          onDelete={handleDeleteConfirmation}
          onCancel={handleDeleteClose}
        />
      )}
    </>
  );
};
