import { AxiosError } from "axios";

export type Entity =
  | "attachment"
  | "attribute"
  | "attributeSelection"
  | "category"
  | "freight"
  | "group"
  | "invoiceDisclosure"
  | "logic"
  | "model"
  | "modelRoom"
  | "option"
  | "optionPricing"
  | "optionRoom"
  | "order"
  | "orderCard"
  | "retailerManufacturer"
  | "retailerNote"
  | "section"
  | "series"
  | "session"
  | "stateFee"
  | "states"
  | "subgroup"
  | "surcharge"
  | "tenant"
  | "tenantFreight"
  | "user"
  | "userRole"
  | "vendor";

const defaultErrorMessage = "Something went wrong. Please try again..";

type GetApiResponseErrorMessage = (entity?: Entity) => string;

const apiResponseErrorMessages: Record<
  /**
   * The key should be of the form {HTTP Status:number} {HTTP StatusText:string}.
   * i.e. '200 OK'
   */
  string,
  GetApiResponseErrorMessage | undefined
> = {
  "200 OK": undefined,
  "204 No Content": undefined,
  "206 Partial Content": undefined,
  "400 Bad Request": undefined,
  "400 Invalid Guid": undefined,
  "400 Invalid Method": undefined,
  "400 Invalid Request": undefined,
  "400 Missing Fields": () => "Some required fields are missing.",
  "400 File Too Big": () => "File too large. File must be < 2mb",
  "400 Continue": () => "File too large. File must be < 2mb",
  "400 Incorrect File Type": () => "Incorrect file type.",
  "400 Code Timeout": undefined,
  "400 Already Verified": undefined,
  "400 Request Too Soon": undefined,
  "400 Logged In": undefined,
  "400 Invalid Code": undefined,
  "400 Invalid User": () => "Invalid user",
  "400 Invalid Model": () => "Model is invalid",
  "400 No Groups": () => "No Groups",
  "400 No Subgroups": () => "No Subgroups",
  "400 Bad Request | Subgroup is not valid": () => "Subgroup is not valid",
  "400 No Options": () => "No Options",
  "401 Not Authorized": undefined,
  "401 Invalid Captcha": undefined,
  "401 Invalid Code": undefined,
  "403 Forbidden": undefined,
  "404 Invalid Path": undefined,
  "404 Not Found": undefined,
  "409 Conflict": (entity?: Entity) => {
    switch (entity) {
      case "attribute":
        return "An attribute already exists with this name.";
      case "attributeSelection":
        return "An attribute selection already exists with this name.";
      case "category":
        return "A category already exists with this name.";
      case "group":
        return "A group already exists with this name.";
      case "subgroup":
        return "A subgroup already exists with this name.";
      case "series":
        return "An active series already exists with this name.";
      case "surcharge":
        return "Surcharge for current series exists for provided dates";
      case "stateFee":
        return "Only one matching State + Construction Type + Per is allowed.";
      case "vendor":
        return "Vendor identifier is already assigned. Vendor identifier has been updated. Please re-submit.";
      case "user":
        return "User already exists with this email address.";
      case "tenant":
        return "Email already exists. Please provide a unique email address.";
      case "orderCard":
        return "Changing the retailer when there is retailer-specific information in the quote is not allowed.";
      default:
        return "Entity already exists.";
    }
  },
  "422 Invalid File Type": () => "Invalid file type.",
  "500 Code 000": undefined,
  "500 Code 001": undefined,
  "500 Code 002": undefined,
  "500 Code 003": undefined,
  "500 Code 010": undefined,
  "500 Code 011": undefined,
  "500 Code 012": undefined,
  "500 Code 013": undefined,
  "500 Code 014": undefined,
  "500 Code 015": undefined,
  "500 Code 016": undefined,
  "500 Code 017": undefined,
  "500 Code 020": undefined,
  "500 Code 021": undefined,
  "500 Code 022": undefined,
  "500 Code 023": undefined,
  "500 Code 024": undefined,
  "500 Code 025": undefined,
  "500 Code 026": undefined,
  "500 Code 030": undefined,
  "500 Code 031": undefined,
  "500 Code 040": undefined,
  "500 Code 041": undefined,
  "500 Code 042": undefined,
  "500 Code 043": undefined,
  "500 Code 044": undefined,
  "500 Code 045": undefined,
  "500 Code 046": undefined,
  "500 Code 047": undefined,
  "500 Code 050": undefined,
  "500 Code 060": undefined,
  "500 Code 061": undefined,
  "500 Code 062": undefined,
  "500 Code 063": undefined,
  "500 Code 064": undefined,
  "500 Code 065": undefined,
  "500 Code 066": undefined,
  "500 Code 067": undefined,
  "500 Code 068": undefined,
  "500 Code 069": undefined,
  "500 Code 070": undefined,
  "500 Code 071": undefined,
  "500 Code 072": undefined,
  "500 Code 073": undefined,
  "500 Code 074": undefined,
  "500 Code 080": undefined,
  "500 Code 081": undefined,
  "500 Code 082": undefined,
  "500 Code 083": undefined,
  "500 Code 090": undefined,
  "500 Code 091": undefined,
  "500 Code 092": undefined,
  "500 Code 093": undefined,
  "500 Code 094": undefined,
  "500 Code 095": undefined,
  "500 Code 096": undefined,
  "500 Code 097": undefined,
  "500 Code 098": undefined,
  "500 Code 100": undefined,
  "500 Code 101": undefined,
  "500 Code 102": undefined,
  "500 Code 103": undefined,
  "500 Code 104": undefined,
  "500 Code 110": undefined,
  "500 Code 111": undefined,
  "500 Code 112": undefined,
  "500 Code 120": undefined,
  "500 Code 121": undefined,
  "500 Code 122": undefined,
  "500 Code 123": undefined,
  "500 Code 124": undefined,
  "500 Code 130": undefined,
  "500 Code 131": undefined,
  "500 Code 132": undefined,
  "500 Code 133": undefined,
  "500 Code 134": undefined,
  "500 Code 140": undefined,
  "500 Code 141": undefined,
  "500 Code 142": undefined,
  "500 Code 143": undefined,
  "500 Code 144": undefined,
  "500 Code 145": undefined,
  "500 Code 150": undefined,
  "500 Code 151": undefined,
  "500 Code 152": undefined,
  "500 Code 153": undefined,
  "500 Code 154": undefined,
  "500 Code 155": undefined,
  "500 Code 156": undefined,
  "500 Code 157": undefined,
  "500 Code 158": undefined,
  "500 Code 159": undefined,
  "500 Code 160": undefined,
  "500 Code 161": undefined,
  "500 Code 162": undefined,
  "500 Code 163": undefined,
  "500 Code 164": undefined,
  "500 Code 165": undefined,
  "500 Code 166": undefined,
  "500 Code 167": undefined,
  "500 Code 170": undefined,
  "500 Code 171": undefined,
  "500 Code 172": undefined,
  "500 Code 173": undefined,
  "500 Code 174": undefined,
  "500 Code 180": undefined,
  "500 Code 181": undefined,
  "500 Code 182": undefined,
  "500 Code 183": undefined,
  "500 Code 190": undefined,
  "500 Code 191": undefined,
  "500 Code 192": undefined,
  "500 Code 193": undefined,
  "500 Code 194": undefined,
  "500 Code 200": undefined,
  "500 Code 201": undefined,
  "500 Code 202": undefined,
  "500 Code 203": undefined,
  "500 Code 210": undefined,
  "500 Code 211": undefined,
  "500 Code 220": undefined,
  "500 Code 221": undefined,
  "500 Code 230": undefined,
  "500 Code 231": undefined,
  "500 Code 240": undefined,
  "500 Code 241": undefined,
  "500 Code 250": undefined,
  "500 Code 251": undefined,
  "501 Not Implemented": undefined,
} as const;

export const getApiErrorMessage = (
  axiosError: AxiosError,
  entity?: Entity
): string => {
  const { status, data } = axiosError.response ?? {};
  const errorMessageKey = `${status} ${data}`;
  const errorMessageFunc = apiResponseErrorMessages[errorMessageKey];
  const errorMessage = errorMessageFunc?.(entity);
  return errorMessage ?? defaultErrorMessage;
};
