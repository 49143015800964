import { ConstructionOption } from "./constructionOption";

export type ConstructionSubgroup = {
  constructionSubgroupGuid: string;
  subgroupName: string;
  subgroupType: "rd" | "od" | "rh";
  sortOrder: number;
  options: ConstructionOption[];
  isActive: boolean;
};

export const getConstructionGroupTypeDisplayName = (
  type: ConstructionSubgroup["subgroupType"]
): string => {
  const displayName: Record<ConstructionSubgroup["subgroupType"], string> = {
    rd: "Required (Displayed)",
    rh: "Required (Hidden)",
    od: "Optional (Displayed)",
  };
  return displayName[type];
};

export const subgroupTypeOptions = [
  { value: "rd", label: "Required (Displayed)" },
  { value: "rh", label: "Required (Hidden)" },
  { value: "od", label: "Optional (Displayed)" },
];
