import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  getShipToDisplayName,
  type OrderCardBusinessLogistics as OrderCardBusinessLogisticsType,
} from "../../types/orderCardBusinessLogistics";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import InputField from "../../components/InputField";
import SelectField from "../../components/SelectField";
import { Order, getSoldAsTypeDisplayName } from "../../types/order";
import { formatAsDate } from "../../utils/date";
import { getOrderCardError } from "../../types/orderCard";
import { Popover } from "../../components/Popover";
import { InfoOutlined } from "@mui/icons-material";
import { FormLabelAsterisk } from "../../components/FormLabelAsterisk";
import { useSession } from "../../hooks/useSession";

interface OrderCardBusinessLogisticsProps {
  order: Order;
  orderCard: OrderCardBusinessLogisticsType;
  expanded: boolean;
  groupIndex: number;
}

type FormData = {
  soldAs: "r" | "s" | "y";
  retailerTenantGuid: string | null;
  retailSalesRep: string | null;
  vinNumber: string | null;
  transportationVendorGuid: string | null;
  shipTo: "r" | "b" | "p";
};

const getDefaultValues = (
  orderCard: OrderCardBusinessLogisticsType | null
): FormData => {
  return {
    soldAs: orderCard?.cardData.soldAs ?? "r",
    retailerTenantGuid: orderCard?.cardData.retailerTenantGuid ?? "",
    retailSalesRep: orderCard?.cardData.retailSalesRep ?? "",
    vinNumber: orderCard?.cardData.vinNumber ?? "",
    transportationVendorGuid:
      orderCard?.cardData.transportationVendorGuid ?? "",
    shipTo: orderCard?.cardData.shipTo ?? "r",
  };
};

export const OrderCardBusinessLogistics: React.FC<
  OrderCardBusinessLogisticsProps
> = ({ order, orderCard, expanded, groupIndex }) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const {
    control,
    register,
    reset,
    formState,
    getValues,
    watch,
    setValue,
    setError,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const retailerTenantGuid = watch("retailerTenantGuid");
  const transportationVendorGuid = watch("transportationVendorGuid");
  const { user } = useSession();

  const selectedRetailerLocation = useMemo(() => {
    if (!retailerTenantGuid) return "";

    let selected;
    if (orderCard.retailers.length === 1) {
      selected = orderCard.retailers[0];
    } else {
      selected = orderCard.retailers.find(
        (retailer) => retailer.tenantGuid === retailerTenantGuid
      );
    }
    if (!selected?.tenantCity || !selected?.tenantState) return "";

    return `${selected?.tenantCity}, ${selected?.tenantState}`;
  }, [retailerTenantGuid, orderCard.retailers]);

  const retailerSelectionOptions = useMemo(() => {
    if (!orderCard.retailers) return [];
    return orderCard.retailers.map((retailer) => ({
      label: `${retailer.tenantName}`,
      citystate:
        retailer.tenantCity && retailer.tenantState
          ? `${retailer.tenantCity}, ${retailer.tenantState}`
          : "",
      value: retailer.tenantGuid,
    }));
  }, [orderCard.retailers]);

  const transportationSelectionOptions = useMemo(() => {
    if (!orderCard.vendors) return [];
    return orderCard.vendors.map((retailer) => ({
      label: `${retailer.vendorName}`,
      value: retailer.vendorGuid,
    }));
  }, [orderCard.vendors]);

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  useEffect(() => {
    if (orderCard.errors.length === 0) return;

    if (!!getOrderCardError(orderCard, "eblr")) {
      setError("retailerTenantGuid", {
        type: "manual",
        message: "Retailer Inactive",
      });
    }
    if (!!getOrderCardError(orderCard, "eblv")) {
      setError("transportationVendorGuid", {
        type: "manual",
        message: "Vendor Inactive",
      });
    }
  }, [setError, disabled, orderCard]);

  useEffect(() => {
    if (orderCard.cardData.soldAs !== "y") {
      if (
        transportationSelectionOptions.length === 1 &&
        transportationVendorGuid == null
      ) {
        setValue(
          "transportationVendorGuid",
          transportationSelectionOptions[0].value
        );
      }

      if (retailerSelectionOptions.length === 1 && retailerTenantGuid == null) {
        setValue("retailerTenantGuid", retailerSelectionOptions[0].value);
      }
    }
  }, [
    orderCard.cardData.soldAs,
    setValue,
    transportationSelectionOptions,
    retailerSelectionOptions,
    transportationVendorGuid,
    retailerTenantGuid,
  ]);

  const getOrderCardData = (
    formData: FormData
  ): OrderCardBusinessLogisticsType["cardData"] => {
    return {
      ...formData,
      orderIdentifier: orderCard.cardData.orderIdentifier,
    };
  };

  const handleBlurField = (value?: string) => {
    if (value && value === "y") {
      setValue("retailerTenantGuid", null);
      setValue("retailSalesRep", null);
      setValue("transportationVendorGuid", null);
    }

    const cardData = getOrderCardData(getValues());
    saveOrderCard(orderCard.orderCardGuid, cardData);
    setEditingOrderCard(null);    
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    const cardData = getOrderCardData(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, cardData);
  };
  
  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
      heading={
        <Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1">
              Quote Information <FormLabelAsterisk />
            </Typography>
            {(!user?.isRetailUser && orderCard.isRetailerReadOnly) && (
              <Popover
                button={
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                }
                body={
                  <Box>
                    <Typography variant="body1" fontWeight={600}>
                      Read-Only Retailer
                    </Typography>
                    <Typography variant="body1">
                      When a Quote includes retailer-specific data, changing the retailer or selecting Sold As Yard is not allowed.
                    </Typography>
                  </Box>
                }
              />
          )}
          </Stack>
        </Stack>
      }
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6} item>
          <Controller
            name="soldAs"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <FormLabel required>Sold As</FormLabel>
                <ToggleButtonGroup
                  {...field}
                  disabled={disabled}
                  sx={{ mt: 0 }}
                  fullWidth
                  value={field.value}
                  onChange={(_, value) => {
                    if (value === null) return;
                    field.onChange(value);
                    handleBlurField(value);
                  }}
                  exclusive
                >
                  <ToggleButton value="r">
                    {getSoldAsTypeDisplayName("r")}
                  </ToggleButton>
                  <ToggleButton value="s">
                    {getSoldAsTypeDisplayName("s")}
                  </ToggleButton>
                  <ToggleButton value="y" disabled={orderCard.isRetailerReadOnly}>
                    {getSoldAsTypeDisplayName("y")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            )}
          />
        </Grid>
        {orderCard.cardData.soldAs !== "y" && (
          <Grid xs={12} sm={12} md={6} item>
            <Controller
              control={control}
              name="retailerTenantGuid"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  label="Retailer"
                  required
                  onFocus={handleEditingField}
                  placeholder="Select Retailer"
                  fullWidth
                  options={retailerSelectionOptions}
                  disabled={disabled || orderCard.isRetailerReadOnly}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  value={field.value ?? ""}
                  onBlur={() => {
                    handleBlurField();
                    field.onBlur();
                  }}
                />
              )}
            />
          </Grid>
        )}
        {orderCard.cardData.soldAs !== "y" && (
          <Grid xs={12} sm={12} md={6} item>
            <InputField
              {...register("retailSalesRep", {
                onBlur: () => handleBlurField(),
              })}
              label="Retailer Sales Rep"
              required
              onFocus={handleEditingField}
              placeholder="Enter Retailer Sales Rep"
              fullWidth
              disabled={disabled}
              sx={{ marginBottom: 2 }}
              error={!!formState.errors.retailSalesRep}
              helperText={formState.errors.retailSalesRep?.message}
            />
          </Grid>
        )}
        {orderCard.cardData.soldAs !== "y" && (
          <Grid xs={12} sm={12} md={6} item>
            <InputField
              label="Retailer Location"
              fullWidth
              onFocus={handleEditingField}
              disabled
              value={selectedRetailerLocation}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        )}
        <Grid xs={12} sm={12} md={6} item>
          <InputField
            label="Date Created"
            fullWidth
            disabled
            value={formatAsDate(order.createdTimestamp)}
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        {!order.isTemplate ? (
          <>
            <Grid xs={12} sm={12} md={6} item>
              <InputField
                label="ID"
                fullWidth
                disabled
                value={orderCard.cardData.orderIdentifier}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid xs={12} sm={12} md={6} item>
              <InputField
                {...register("vinNumber", { onBlur: () => handleBlurField() })}
                label="VIN #"
                placeholder="Enter VIN Number"
                fullWidth
                onFocus={handleEditingField}
                disabled={disabled}
                sx={{ marginBottom: 2 }}
                error={!!formState.errors.vinNumber}
                helperText={formState.errors.vinNumber?.message}
              />
            </Grid>
            <Grid xs={12} sm={12} md={6} item>
              {orderCard.cardData.soldAs !== "y" && (
                <Controller
                  control={control}
                  name="transportationVendorGuid"
                  render={({ field, fieldState }) => (
                    <SelectField
                      {...field}
                      label="Transportation"
                      placeholder="Select Transportation"
                      fullWidth
                      options={transportationSelectionOptions}
                      onFocus={handleEditingField}
                      disabled={disabled}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={field.value ?? ""}
                      onBlur={() => {
                        handleBlurField();
                        field.onBlur();
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6} item>
              {orderCard.cardData.soldAs !== "y" && (
                <Controller
                  name="shipTo"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel required>Ship To</FormLabel>
                      <ToggleButtonGroup
                        {...field}
                        disabled={disabled}
                        sx={{ mt: 0 }}
                        fullWidth
                        value={field.value}
                        onChange={(_, value) => {
                          if (value === null) return;
                          field.onChange(value);
                          handleBlurField();
                        }}
                        exclusive
                      >
                        <ToggleButton value="r">
                          {getShipToDisplayName("r")}
                        </ToggleButton>
                        <ToggleButton value="b">
                          {getShipToDisplayName("b")}
                        </ToggleButton>
                        <ToggleButton value="p">
                          {getShipToDisplayName("p")}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormControl>
                  )}
                />
              )}
            </Grid>
          </>
        ) : null}
      </Grid>
    </OrderCardBase>
  );
};
