import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Search from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { EmptyState } from "../../components/EmptyState";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import {
  Grid,
  Stack,
  Button,
  OutlinedInput,
  InputAdornment,
  Box,
  Typography,
} from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Series } from "../../types/series";
import { AccordionCard } from "../../components/AccordionCard";
import { NewEditSeriesModal } from "./NewEditSeriesModal";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import {
  Model,
  getStartDateFormatted,
  getEndDateFormatted,
} from "../../types/model";
import { formatAsCurrency } from "../../utils/number";
import { Table } from "../../components/Table";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { TableDraggable } from "../../components/TableDraggable";
import { useSession } from "../../hooks/useSession";
import { AutocompleteField } from "../../components/AutocompleteField";
import { Controller, useForm } from "react-hook-form";
import { Retailer } from "../../types/tenant";

type TableModel = Model & { seriesGuid: Series["seriesGuid"] };

type FormData = {
  manufacturerGuid: string | null;
};

const getDefaultValues = (): FormData => {
  return {
    manufacturerGuid: null,
  };
};

const ManageModelSeriesRoute: React.FC = () => {
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { user } = useSession();
  const [isEditing, setIsEditing] = useState(false);
  const [editingSeries, setEditingSeries] = useState<Series | undefined>(
    undefined
  );
  const [searchText, setSearchText] = useState("");
  const { data: seriesData, request: seriesRequest } =
    useApiRequest<Series[]>(false);
  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  const [series, setSeries] = useState<Series[]>(seriesData ?? []);

  const filteredSeries = useMemo(() => {
    if (!series) {
      return [];
    }
    return series.filter((s) =>
      s.seriesName.toLowerCase().includes(searchText)
    );
  }, [series, searchText]);

  const seriesWithTableModels = filteredSeries.map((s) => {
    const tableModels: TableModel[] = s.models.map((m) => ({
      ...m,
      seriesGuid: s.seriesGuid,
    }));
    const series = {
      ...s,
      models: tableModels,
    };
    return series;
  });

  usePageMetadata({ title: `Manage ${routes.manageDataModelSeries.label}` });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataModelSeries.label }]);
  }, [setBreadcrumbs]);

  const refreshSeries = useCallback(() => {
    seriesRequest("/company/series", { method: "GET" });
  }, [seriesRequest]);

  useEffect(() => {
    refreshSeries();
  }, [refreshSeries]);

  useEffect(() => {
    setSeries(seriesData ?? []);
  }, [seriesData]);

  const handleNewButtonClick = () => {
    setEditingSeries(undefined);
    setIsEditing(true);
  };

  const handleEditSeriesClick =
    (series: Series) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setEditingSeries(series);
      setIsEditing(true);
    };

  const handleSave = useCallback(() => {
    refreshSeries();
    setIsEditing(false);
  }, [refreshSeries]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const getRowId = useCallback((row: Model): string => {
    return row.modelGuid;
  }, []);

  const modelTableColumns: GridColDef<TableModel>[] = useMemo(
    () => [
      {
        field: "modelName",
        headerName: "Model Name",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "modelNumber",
        headerName: "Model Number",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "modelBasePrice",
        headerName: "Price",
        flex: 1,
        minWidth: 120,
        valueGetter(value) {
          const price = formatAsCurrency(value);
          return price;
        },
      },
      {
        field: "startDate",
        headerName: "Begin Date",
        flex: 1,
        minWidth: 100,
        valueGetter(_value, row) {
          const formattedStartDate = getStartDateFormatted(row);
          return formattedStartDate;
        },
      },
      {
        field: "endDate",
        headerName: "End Date",
        flex: 1,
        minWidth: 100,
        valueGetter(_value, row) {
          const formattedEndDate = getEndDateFormatted(row);
          return formattedEndDate;
        },
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        sortable: false,
        renderCell: (params) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: user?.isRetailUser ? "View" : "Edit",
                  onClick: () => {
                    navigate(
                      generatePath(
                        routes.manageDataModelSeriesModelsEdit.path,
                        {
                          seriesGuid: params.row.seriesGuid,
                          modelGuid: params.row.modelGuid,
                        }
                      )
                    );
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [navigate, user?.isRetailUser]
  );

  const handleDragEnd = useCallback(
    (series: Series, sortedModels: Model[]) => {
      const updatedModels: Model[] = sortedModels.map((model, index) => ({
        ...model,
        sortOrder: index,
      }));
      const updatedSeries: Series = { ...series, models: updatedModels };

      updateSortOrderRequest("/company/models/sortorder/update", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: updatedModels.map((m) => ({
          modelGuid: m.modelGuid,
          sortOrder: m.sortOrder,
        })),
      });

      setSeries((prevSeries) => {
        return prevSeries.map((s) => {
          if (s.seriesGuid !== updatedSeries.seriesGuid) {
            return s;
          }
          return { ...s, ...updatedSeries };
        });
      });
    },
    [updateSortOrderRequest]
  );

  const {
    control,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(),
  });

  const manufacturerGuid = watch("manufacturerGuid");
  const {
    data: manufacturerData,
    loading: manufacturerLoading,
    request: manufacturerRequest,
  } = useApiRequest<Retailer[]>();

  useEffect(() => {
    if (!user?.isRetailUser) return;
    manufacturerRequest("/retailer/manufacturers", {
      method: "GET",
    });
  }, [manufacturerRequest,user?.isRetailUser]);

  const manufacturerSelectionOptions = useMemo(() => {
    if (!manufacturerData) return [];
    return manufacturerData.map((mfg) => ({
      label: `${mfg.tenantName}`,
      citystate:
        mfg.tenantCity && mfg.tenantState
          ? `${mfg.tenantCity}, ${mfg.tenantState}`
          : "",
      value: mfg.tenantGuid,
    }));
  }, [manufacturerData]);

  useEffect(() => {
    if (!user?.isRetailUser || !manufacturerGuid) {
      return;
    }
    seriesRequest(`retailer/${manufacturerGuid}/series`,
      {
        method: "GET",
      }
    );
  }, [seriesRequest, user?.isRetailUser, manufacturerGuid]);

  useEffect(() => {    
    if (user?.isRetailUser && manufacturerSelectionOptions && manufacturerSelectionOptions.length > 0) {
      const savedValue = localStorage.getItem("retailerManufacturerSeriesListGuid");
      if (savedValue) {
        manufacturerSelectionOptions.forEach(element => {
          if (element.value === savedValue) {
            setValue("manufacturerGuid",savedValue);      
            return;
          }
        });
      }
      if (manufacturerSelectionOptions.length === 1) {
        setValue("manufacturerGuid",manufacturerSelectionOptions[0].value);
      }
    }
  },[manufacturerSelectionOptions, user?.isRetailUser, setValue]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
        marginBottom={2}
      >
        <Grid item>
          <Stack spacing={2} direction="row">
            <Box sx={{ mb: 2, width: 290 }}>
              <Controller
                control={control}
                name="manufacturerGuid"
                rules={{ required: "Please select a value." }}
                render={({ field }) => {
                  const selectedValue = manufacturerSelectionOptions.find(
                    (s) => s.value === field.value
                  );

                  return (
                    <>
                      {user?.isRetailUser && (
                        <AutocompleteField
                          {...field}
                          disabled={manufacturerLoading}
                          options={manufacturerSelectionOptions}
                          value={selectedValue ?? null}
                          placeholder="Select Manufacturer"
                          onChange={(_, value) => {
                            field.onChange(value?.value ?? null);
                            if (value?.value) {
                              localStorage.setItem("retailerManufacturerSeriesListGuid",value.value);
                            } else {
                              localStorage.removeItem("retailerManufacturerSeriesListGuid");
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box p={1} component="li" {...props}>
                              <Stack
                                spacing={1}
                                direction="row"
                                alignContent="center"
                              >
                                <Typography variant="body1">
                                  {option.label}
                                </Typography>
                                <Typography variant="body2" color="gray" pt={0.3}>
                                  {option.citystate}
                                </Typography>
                              </Stack>
                            </Box>
                          )}
                        />
                      )}
                    </>
                  );
                }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction={"row-reverse"}>
            {!user?.isRetailUser && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewButtonClick}
              >
                + Series
              </Button>
            )}
            <OutlinedInput
              placeholder="Search"
              onChange={handleSearchChange}
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {series && series.length === 0 ?
        user?.isRetailUser ? (
          <EmptyState
            heading="Select Manufacturer to view"
            body=""
            maxWidth = "355px"
          />
        ) : (
          <EmptyState
            heading="No Models or Series"
            body="At least one model and series must be added before a quote can be created"
          />
      ) : series && series.length > 0 ? (
        <>
          {seriesWithTableModels.map((s) => {
            return (
              <AccordionCard
                key={s.seriesGuid}
                heading={s.seriesName}
                endActions={                  
                  <Stack direction="row" alignItems="center" spacing={2}>
                    {!user?.isRetailUser && (
                      <>
                        <Button
                          startIcon={<EditIcon />}
                          onClick={handleEditSeriesClick(s)}
                        >
                          Edit
                        </Button>
                        <Button
                          component={Link}
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                          to={generatePath(
                            routes.manageDataModelSeriesModelsAdd.path,
                            { seriesGuid: s.seriesGuid }
                          )}
                        >
                          Model
                        </Button>
                      </>
                    )}
                  </Stack>
                }
                detailsSx={{ padding: 0 }}
              >
                {user?.isRetailUser ? (
                  <Table
                    columns={modelTableColumns}
                    rows={s.models}
                    rowSelection={false}
                    hideFooter
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnResize
                    disableRowSelectionOnClick
                    getRowId={getRowId}
                    autoHeight
                    sx={tableSx}
                  />
                ) : 
                  <TableDraggable
                    columns={modelTableColumns}
                    rows={s.models}
                    rowSelection={false}
                    hideFooter
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnResize
                    disableRowSelectionOnClick
                    getRowId={getRowId}
                    autoHeight
                    sx={tableSx}
                    onDragEnd={(sortedModels) => handleDragEnd(s, sortedModels)}
                  />
                }
                </AccordionCard>
            );
          })}
        </>
      ) : null}
      {isEditing && (
        <NewEditSeriesModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSaveSuccessful={handleSave}
          series={editingSeries}
          isNew={!editingSeries}
        />
      )}
    </>
  );
};

const tableSx: ComponentProps<typeof Table>["sx"] = {
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  "& .MuiDataGrid-row--lastVisible": {
    "& .MuiDataGrid-cell:nth-of-type(2)": {
      borderBottomLeftRadius: "8px",
    },
    "& .MuiDataGrid-cell:last-of-type": {
      borderBottomRightRadius: "8px",
    },
  },
};

export default ManageModelSeriesRoute;
