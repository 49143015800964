import { Button, Chip, Stack } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useSession } from "../../hooks/useSession";
import { SafetyCheckOutlined } from "@mui/icons-material";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { type OrderCardSubgroupOption as OrderCardSubgroupOptionType } from "../../types/orderCardSubgroupOption";
import { type OrderCardCustomSubgroup as OrderCardCustomSubgroupType } from "../../types/orderCardCustomSubgroup";
import { type OrderCardSalesChangeOrder as OrderCardSalesChangeOrderType } from "../../types/orderCardSalesChangeOrder";
import { OrderCard } from "../../types/orderCard";
import { OrderCardEditResponse } from "./orderCardEditResponse";

interface OrderCardApproveDenyButtonsProps {
  orderCard:
    | OrderCardCustomSubgroupType
    | OrderCardSubgroupOptionType
    | OrderCardSalesChangeOrderType;
  denyButtonText: string;
  refreshOrder?: () => void | undefined;
  onOrderCardEdited?: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void | undefined;
  onDenyClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const OrderCardApproveDenyButtons: React.FC<
  OrderCardApproveDenyButtonsProps
> = ({ orderCard, denyButtonText, onDenyClick, refreshOrder, onOrderCardEdited }) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { user } = useSession();
  const {
    data: saveOrderCardData,
    status: approvalStatus,
    request: approveRequest,
    loading: approvalLoading,
    errorMessage: approvalErrorMessage,
  } = useApiRequest<OrderCardEditResponse<OrderCard>>();

  const isAdmin = useMemo(() => {
    if (!user) return false;
    return ["owner", "admin"].includes(user.userRole);
  }, [user]);

  useEffect(() => {
    if (approvalStatus === "ok") {
      if (onOrderCardEdited && saveOrderCardData) {
        onOrderCardEdited(saveOrderCardData);
      } else if (refreshOrder) {
        refreshOrder();
      }
    }
  }, [approvalStatus, refreshOrder, onOrderCardEdited, saveOrderCardData]);

  useEffect(() => {
    if (approvalErrorMessage) {
      showErrorToast({ message: approvalErrorMessage });
    }
  }, [approvalErrorMessage, showErrorToast]);

  const handleApproveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    approveRequest(`/ordercards/action/approve`, {
      method: "POST",
      data: {
        orderCardGuid: orderCard.orderCardGuid,
      }
    });
  };

  return (
    <>
      {(isAdmin && !user?.isRetailUser) ? (
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" color="primary" onClick={onDenyClick}>
            {denyButtonText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApproveClick}
            disabled={approvalLoading}
          >
            Approve
          </Button>
        </Stack>
      ) : (
        <Chip
          label={
            <Stack direction="row">
              <SafetyCheckOutlined fontSize="small" />
              {user?.isRetailUser ? "Pending Mfg Review" : "Pending Admin Approval"}
            </Stack>
          }
          size="medium"
          color="primary"
          variant="outlined"
          sx={({ palette }) => ({
            backgroundColor: "white",
            color: palette.primary.main,
          })}
        />
      )}
    </>
  );
};
