import { orderCardTypes } from "./orderCardTypes";
import { OrderCardBusinessBuyer } from "./orderCardBusinessBuyer";
import { OrderCardError } from "./orderCard";

export type OrderCardFinancialFeesFreightError = OrderCardError<{ freight: 0 }>;

export type OrderCardFinancialFees = Omit<
  OrderCardBusinessBuyer,
  "orderCardType" | "cardData"
> & {
  totalFees: number;
  orderCardType: (typeof orderCardTypes)["financialFees"];
  isLocked: boolean;
  errors: OrderCardError[];
  cardData: {
    fees: {
      feeName:
        | "Freight"
        | "State Fees"
        | "HUD Fees"
        | "Trim Out"
        | "MOD Fees"
        | "Taxes"
        | string;
      feeAmount: number;
    }[];
  };
};

export const isOrderCardFinancialFeeReadOnly = (
  feeName: OrderCardFinancialFees["cardData"]["fees"][0]["feeName"]
): boolean => {
  return ["Freight", "State Fees"].includes(feeName);
};
