import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { Badge } from "@mui/material";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import ImagesearchRollerOutlinedIcon from "@mui/icons-material/ImagesearchRollerOutlined";
import { SidebarItem, SidebarLayout } from "./SidebarLayout";
import { routes } from "../routes";
import { useApiRequest } from "../hooks/useApiRequest";
import { Tenant, TenantErrors } from "../types/tenant";
import { ContentPaste, Handyman } from "@mui/icons-material";

export const SidebarLayoutManageData: React.FC = () => {
  const { data: companyData, request: companyRequest } =
    useApiRequest<Tenant>(false);

  const refreshCompany = useCallback(() => {
    companyRequest("/company", { method: "GET" });
  }, [companyRequest]);

  useEffect(() => {
    refreshCompany();
  }, [refreshCompany]);

  const contextValue: SidebarLayoutManageDataContextType = useMemo(
    () => ({
      refreshSidebar: refreshCompany,
    }),
    [refreshCompany]
  );

  const sidebarItems: SidebarItem[] = useMemo(() => {
    const renderErrorBadge = (error: TenantErrors) => {
      return companyData?.errors.includes(error) ? (
        <Badge color="error" variant="dot" />
      ) : undefined;
    };

    return [
      {
        icon: <FactoryOutlinedIcon />,
        iconSecondary: renderErrorBadge("company"),
        ...routes.manageDataCompany,
      },
      {
        icon: <ContentPaste />,
        iconSecondary: renderErrorBadge("inventory"),
        ...routes.retailerInventory,
      },
      {
        icon: <HomeOutlinedIcon />,
        iconSecondary: renderErrorBadge("models"),
        ...routes.manageDataModelSeries,
      },
      {
        icon: <ChecklistRtlIcon />,
        ...routes.manageDataSelectionOptions,
      },
      {
        icon: <ImagesearchRollerOutlinedIcon />,
        iconSecondary: renderErrorBadge("attributes"),
        ...routes.manageDataAttributes,
      },
      {
        icon: <StorefrontOutlinedIcon />,
        iconSecondary: renderErrorBadge("retailers"),
        ...routes.manageDataRetailers,
      },
      {
        icon: <ShoppingCartOutlinedIcon />,
        iconSecondary: renderErrorBadge("vendors"),
        ...routes.manageDataVendors,
      },
      {
        icon: <AccountCircleOutlinedIcon />,
        iconSecondary: renderErrorBadge("users"),
        ...routes.manageDataUsers,
      },
      {
        icon: <WidgetsOutlinedIcon />,
        iconSecondary: renderErrorBadge("templates"),
        ...routes.manageDataTemplates,
      },
      {
        icon: <LocalShippingOutlinedIcon />,
        iconSecondary: renderErrorBadge("freights"),
        ...routes.manageDataFreight,
      },
      {
        icon: <ReceiptLongOutlinedIcon />,
        iconSecondary: renderErrorBadge("disclosures"),
        ...routes.manageDataInvoiceDisclosures,
      },
      {
        icon: <AccountBalanceOutlinedIcon />,
        iconSecondary: renderErrorBadge("statefees"),
        ...routes.manageDataStateFees,
      },
      {
        icon: <CalculateOutlinedIcon />,
        iconSecondary: renderErrorBadge("surcharges"),
        ...routes.manageDataSurchargeCalculator,
      },
      {
        icon: <CallSplitIcon />,
        iconSecondary: renderErrorBadge("logics"),
        ...routes.manageDataConditionalLogic,
      },
      {
        icon: <Handyman />,
        iconSecondary: renderErrorBadge("constructionCosts"),
        ...routes.manageConstructionCosts,
      },
    ];
  }, [companyData?.errors]);

  return (
    <SidebarLayoutManageDataContext.Provider value={contextValue}>
      <SidebarLayout heading="Data Management" sidebarItems={sidebarItems} />
    </SidebarLayoutManageDataContext.Provider>
  );
};

type SidebarLayoutManageDataContextType = {
  refreshSidebar: () => void;
};

const SidebarLayoutManageDataContext =
  createContext<SidebarLayoutManageDataContextType>({
    refreshSidebar: () => {},
  });

export const useSidebarLayoutManageData = () => {
  return useContext(SidebarLayoutManageDataContext);
};
